import axios from "axios";
export const HealthCareFormAPI = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/healthcare`,
      payload
    );
    return response?.data;
  } catch (error) {
    return (
      error?.response?.data ?? {
        success: false,
        message: "Something Went Wrong",
      }
    );
  }
};
export const HospitalityFormAPI = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/hospitality`,
      payload
    );
    return response?.data;
  } catch (error) {
    return (
      error?.response?.data ?? {
        success: false,
        message: "Something Went Wrong",
      }
    );
  }
};
export const TransportationFormAPI = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/transport`,
      payload
    );
    return response?.data;
  } catch (error) {
    return (
      error?.response?.data ?? {
        success: false,
        message: "Something Went Wrong",
      }
    );
  }
};
export const CareerFormAPI = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/career`,
      payload
    );
    return response?.data;
  } catch (error) {
    return (
      error?.response?.data ?? {
        success: false,
        message: "Something Went Wrong",
      }
    );
  }
};
