import React from "react";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import job1 from "../Assets/job1.png";
import job2 from "../Assets/job2.png";
import job3 from "../Assets/job3.png";
import job4 from "../Assets/job4.png";
import job5 from "../Assets/job5.png";
import start1 from "../Assets/start1.png";
import start2 from "../Assets/start2.png";
import start3 from "../Assets/start3.png";
import start4 from "../Assets/start4.png";
import { IoIosSend } from "react-icons/io";
import { MdAttachMoney } from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";
import { MdWork } from "react-icons/md";
import { GiProgression } from "react-icons/gi";
import spiralb from "../Assets/spiralb.png";
import spiralt from "../Assets/spiralt.png";
import forw from "../Assets/forw.png";
import Footer from "../Components/Footer";

const Career = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <section className="section-center">
        {/* container */}
        <div>
          {/* text and button */}
          <div>
            <h3 className=" mt-0 text-3xl md:text-5xl xl:text-6xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Find your dream job here. <br /> come work us
            </h3>
            <h5 className="md:text-xl text-[#1C1F37] lg:text-2xl">
              Your journey to financial freedom just got to its final
              destination.
              <br className="hidden md:block" /> come work with an organization
              that got you at heart
            </h5>
            {/* button */}
            <div className="flex flex-row justify-center gap-4 items-center  mx-auto  mt-4">
              <Link
                to="/careerForm"
                className=" px-6 md:px-8 py-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-Alatal font-semibold shadow-md hover:bg-[#C44B99] hover:text-white"
              >
                Get started
              </Link>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://jobs.gloriousorg.com"
                className=" px-6 md:px-8 py-2 rounded-full border-[#C44B99] text-[#C44B99] font-sans font-semibold shadow-md hover:bg-[#C44B99] hover:text-white"
              >
                Admin Portal
              </a>
            </div>
          </div>
          {/* image */}
          <div className="mt-16 md:mt-20">
            <img src={job1} alt="" />
          </div>
        </div>
      </section>
      {/* top positions */}
      <section className="section-center  xl:mt-32">
        <h5 className="md:text-2xl md:mb-0 text-[#C44B99]">Vacancies </h5>
        <h3 className="mt-0 text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
          Top Positions
        </h3>
        {/* container */}
        <div className="mt-10 md:mt-16 space-y-10 md:flex md:justify-center md:space-y-0 md:gap-4  lg:gap-8 max-w-lg mx-auto">
          {/* cont 1 */}
          <div className="bg-white shadow-lg mx-auto w-56 md:max-w-2xl rounded-lg hover:-translate-y-10 duration-1000">
            {/* image */}
            <div className="w-56 mx-auto cover rounded-t-lg">
              <img src={job2} alt="" className="rounded-t-lg" />
            </div>
            {/* text */}
            <div className="px-4 pt-4 pb-8 space-y-2">
              {/* header */}
              <div className="flex justify-between items-center">
                <h5 className="mt-0 text-left mx-0 text-[#5E6282]">
                  Care giver
                </h5>
                <h5 className="mt-0  text-left mx-0 text-[#5E6282] text-sm">
                  $5,42k/yr
                </h5>
              </div>
              {/* instruction */}
              <div className="flex justify-start items-center gap-2">
                <IoIosSend className="animate-pulse" />
                <Link to="/careerForm" className="font-alata text-[#C44B99] ">
                  Apply now
                </Link>
              </div>
            </div>
          </div>
          {/* cont 2 */}
          <div className="bg-white   shadow-lg mx-auto w-56 md:max-w-2xl rounded-lg hover:-translate-y-10 duration-1000">
            {/* image */}
            <div className="w-56 mx-auto rounded-lg cover">
              <img src={job3} alt="" className="rounded-t-lg" />
            </div>
            {/* text */}
            <div className="px-4 pt-4 pb-8 space-y-2">
              {/* header */}
              <div className="flex justify-between items-center">
                <h5 className="mt-0 text-left mx-0 text-[#5E6282]">
                  Delivery personnel
                </h5>
                <h5 className="mt-0  text-left mx-0 text-[#5E6282] text-sm">
                  $4.42k/yr
                </h5>
              </div>
              {/* instruction */}
              <div className="flex justify-start items-center gap-2">
                <IoIosSend className="animate-pulse" />
                <Link to="/careerForm" className="font-alata text-[#C44B99] ">
                  Apply now
                </Link>
              </div>
            </div>
          </div>
          {/* cont 3 */}
          <div className="bg-white  shadow-lg mx-auto w-56 md:max-w-2xl md:lg:w- rounded-lg hover:-translate-y-10 duration-1000">
            {/* image */}
            <div className="w-56 mx-auto rounded-lg cover">
              <img src={job4} alt="" className="rounded-t-lg" />
            </div>
            {/* text */}
            <div className="px-4 pt-4 pb-8 space-y-2">
              {/* header */}
              <div className="flex justify-between items-center">
                <h5 className="mt-0 text-left mx-0 text-[#5E6282]">
                  Hospitality worker
                </h5>
                <h5 className="mt-0  text-left mx-0 text-[#5E6282] text-sm">
                  $15k/yr
                </h5>
              </div>
              {/* instruction */}
              <div className="flex justify-start items-center gap-2">
                <IoIosSend className="animate-pulse" />
                <Link to="/careerForm" className="font-alata text-[#C44B99] ">
                  Apply now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* wanna join us */}
      <section className="section-center xl:mt-32">
        <h5 className="md:text-2xl md:mb-0 text-[#C44B99]">Wanna join us ? </h5>
        <h3 className="mt-0 text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
          How to Get started
        </h3>
        {/* container */}
        <div className="mt-10 md:flex md:flex-row-reverse justify-center items-center gap-2">
          {/* image */}
          <div className="flex flex-col px-2 md:w-1/2 xl:flex-row md:px-0">
            {/* small */}
            <div className="flex xl:flex-col justify-center items-center mb-4 mx-auto gap-4 w-72 xl:h-96 xl:gap-8  ">
              <img src={start1} alt="" className="w-1/3 " />
              <img src={start2} alt="" className="w-1/3 " />
              <img src={start3} alt="" className="w-1/3 " />
            </div>
            {/* big */}
            <div className="max-w-xs mx-auto xl:max-w-lg xl:mx-0">
              <img src={start4} alt="" />
            </div>
          </div>
          {/* text */}
          <div className="mt-12 md:w-1/2 ">
            <h5 className="md:text-left text-[#C44B99]">
              Are you Interested in{" "}
            </h5>
            <h3 className=" text-3xl mt-3 md:text-left md:text-xl lg:text-3xl md:mt-1 ">
              Working with us ?
            </h3>
            <h5 className="w-96 md:w-80 mx-auto text-center text-[#1C1F37] font-alata text-md md:text-left md:mx-0 lg:w-full  xl:text-xl">
              Then follow these steps as stipulated below and begin your journey
              to financial freedom , job security and work fufillment
            </h5>
            {/* content1 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4">
              <div>
                <h3 className=" text-xl mt-3 md:text-left md:text-xl lg:text-2xl md:mt-1 ">
                  Identify your most qualified position
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  Go through our available positions and identify the position
                  well suited for the qualifications you have currently
                </h5>
              </div>
            </div>
            {/* content22 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4 md:mt-2">
              <div>
                <h3 className=" text-xl mt-3 md:text-left md:text-xl lg:text-2xl md:mt-1 ">
                  Apply on our website
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  Fill out the application form on our website currently whilst
                  providing the appropriate attachments as required
                </h5>
              </div>
            </div>
            {/* content33 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4 md:mt-2">
              <div>
                <h3 className=" text-xl mt-3 md:text-left md:text-xl lg:text-2xl md:mt-1 ">
                  Expect our feedback
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  Upon successfully completion of the application form,expect a
                  feedback from our administrative staff
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* wy choose us */}
      <section className="section-center xl:mt-32">
        <h5 className="md:text-2xl md:mb-0 text-[#C44B99]">Why choose us ? </h5>
        <h3 className="mt-0 text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
          we’ve got your interest at heart
        </h3>
        {/* conatiner */}
        <div className="xl:flex xl:justify-between xl:items-center xl:gap-16 mt-12">
          {/* image */}
          <div className="w-72 mx-auto md:w-96 xl:w-1/3">
            <img src={job5} alt="" />
          </div>
          {/* text */}
          <div className="md:max-w-lg md:mx-auto lg:max-w-3xl xl:w-2/3 ">
            <h3 className="text-2xl md:text-3xl xl:text-left xl:text-3xl max-w-xs mx-auto text-center xl:mx-0 xl:max-w-xl ">
              Get exposed to these working conditions
            </h3>
            {/* values options */}
            <div className="mt-16 space-y-12 lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-8 ">
              {/* value1 */}
              <div>
                {/* icon */}
                <div className="bg-[#c44b99] w-9 h-9  xl:w-12 xl:h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <MdAttachMoney className="text-white text-xl xl:text-2xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-xl xl:text-left">
                    Competitive Pay
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    Get top-dollar for your time and service rendered to our
                    clients
                  </h5>
                </div>
              </div>
              {/* value2 */}
              <div>
                {/* icon */}
                <div className="bg-[#c44b99] w-9 h-9  xl:w-12 xl:h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <AiOutlineSchedule className="text-white text-xl xl:text-2xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-xl xl:text-left">
                    Flexible Schedule
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    Flexible schedules programmed to enable you maintain
                    adequate worklife balance
                  </h5>
                </div>
              </div>
              {/* value3 */}
              <div>
                {/* icon */}
                <div className="bg-[#c44b99] w-9 h-9  xl:w-12 xl:h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <MdWork className="text-white text-xl xl:text-2xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-xl xl:text-left">
                    Job security
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    Guarranteed job security in the absence of any discplinary
                    offence commited
                  </h5>
                </div>
              </div>
              {/* value4 */}
              <div>
                {/* icon */}
                <div className="bg-[#c44b99] w-9 h-9  xl:w-12 xl:h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <GiProgression className="text-white text-xl xl:text-2xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-xl xl:text-left">
                    career progression
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    Get elevated in your career path as opportunities offered
                    aid your career progression
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get intouch */}
      <section className="section-center lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl xl:mt-24 px-6 ">
        <div className="relative">
          <div className="bg-[#E95F8E] px-6 py-10 rounded-md md:px-16 md:py-14 relative overflow-hidden">
            <h3 className="text-white mt-0 text-xl md:text-2xl lg:text-3xl xl:text-3xl">
              Are you ready to kick start your{" "}
              <span className="backimg  animate-pulse z-10">Career </span> with
              us today? Then get in touch !
            </h3>
            {/* button */}
            <div className="mt-6 flex flex-col md:flex-row justify-center items-center gap-4 md:mt-10">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/12027057631"
                className="bg-[#1F7CFF] text-white font-alata px-4 py-1 md:py-2  rounded-md hover:bg-white hover:text-[#5E6282] border-[#1F7CFF] "
              >
                Let's Talk
              </a>
              <Link
                to={"/careerForm"}
                className=" border-2 text- font-alata px-4 py-1 md:py-2  rounded-md text-white hover:bg-white hover:text-[#5E6282] "
              >
                Book an Appointment
              </Link>
            </div>
            {/* spiral */}
            <img
              src={spiralt}
              alt=""
              className="absolute -right-6 -top-6 w-20  opacity-40 md:w-24 xl:w-24"
            />
            <img
              src={spiralb}
              alt=""
              className="absolute left-0 bottom-0 w-20  opacity-40 md:w-32 xl:w-44"
            />
          </div>
          <img
            src={forw}
            alt=""
            className="absolute -right-4 -top-4 w-10 xl:w-16 animate-bounce"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Career;
