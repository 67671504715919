import React from "react";
import Header from "../Components/Header";
import serv1 from "../Assets/serv1.png";
import serv2 from "../Assets/serv2.png";
import serv3 from "../Assets/serv3.png";
import serv4 from "../Assets/serv4.png";
import hos1 from "../Assets/hos1.png";
import hos2 from "../Assets/hos2.png";
import maid from "../Assets/maid.png";
import { Link } from "react-router-dom";
import spiralb from "../Assets/spiralb.png";
import spiralt from "../Assets/spiralt.png";
import forw from "../Assets/forw.png";
import Footer from "../Components/Footer";

const Hospitality = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <section className="section-center px-4">
        {/* section-conainer */}
        <div className="space-y-8 md:flex  md:flex-row-reverse md:space-y-0 md:justify-between md:items-center md:gap-6 lg:gap-8">
          {/* image */}
          <div className="md:w-1/2">
            <img src={maid} alt="" className="w-full" />
          </div>
          {/* text */}
          <div className="space-y-4 md:w-1/2">
            {/* text */}
            <div className="mx-auto text-center space-y-4 md:text-left md:mx-0">
              <h1 className="font-alata font-bold text-5xl xl:6xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 max-w-sm text-center mx-auto md:mx-0 md:text-left">
                Hospitality service you can bank on
              </h1>
              <h5 className="w-72 mx-auto text-center text-[#1C1F37] font-alata text-md md:text-left md:mx-0 lg:w-full xl:text-xl">
                Give your event the hospitality service it deserves. Let our
                staff handle all th hospitality service for your events, hotels
                and households. Be sure we will deliver
              </h5>
            </div>
            {/* button */}
            <div className="flex flex-row justify-center gap-4 items-center  mx-auto md:mx-0 md:justify-start">
              <Link
                to="/hospitalityForm"
                className=" px-8 py-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-sans font-semibold shadow-md"
              >
                Book an Event
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* our service */}
      <section className="section-center mt-24 px-4 ">
        <h5 className="md:text-2xl md:mb-0 text-[#C44B99]">Our Services </h5>
        <h3 className="mt-0 text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
          We Offer Premium Services
        </h3>
        {/* container */}
        <div className="mt-16 space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-y-12 lg:grid-cols-4 lg:gap-x-8 mb-20 md:mb-28 xl:mb-36">
          {/* cont1 */}
          <div className="flex flex-col bg-white  rounded-lg shadow-lg  py-12 max-w-[220px] md:max-w-[300px] md:px-10 md:pb-8 hover:-translate-y-10 border xl:border-2 border-[#C44B99] duration-1000 mx-auto  ">
            <img src={serv1} alt="" className="w-14 mx-auto" />
            <h3 className="mt-8 text-xl text-[#C44B99]">Weddings</h3>
            <h5 className="mt-1 max-w-[180px] md:max-w-[300px]md:mb-0 mx-auto text-sm text-[#1C1F37] ">
              we assign workers to carefully wait on your guests at your wedding
            </h5>
          </div>
          {/* cont2 */}
          <div className="flex flex-col bg-white  rounded-lg shadow-lg border xl:border-2 border-[#C44B99] py-12 max-w-[220px] hover:-translate-y-10 duration-1000 mx-auto md:max-w-[300px] md:px-10 md:pb-8">
            <img src={serv2} alt="" className="w-14 mx-auto" />
            <h3 className="mt-8 text-xl text-[#C44B99]">Events</h3>
            <h5 className="mt-1 max-w-[180px] mx-auto text-sm md:max-w-[300px]md:mb-0 text-[#1C1F37]">
              we assign workers to carefully wait on your guests at your events
            </h5>
          </div>
          {/* cont3 */}
          <div className="flex flex-col bg-white  rounded-lg shadow-lg border xl:border-2 border-[#C44B99] py-12 max-w-[220px] hover:-translate-y-10 duration-1000 mx-auto md:max-w-[300px] md:px-10 md:pb-8">
            <img src={serv3} alt="" className="w-14 mx-auto" />
            <h3 className="mt-8 text-xl text-[#C44B99]">House Parties</h3>
            <h5 className="mt-1 max-w-[180px] mx-auto text-sm md:max-w-[300px]md:mb-0 text-[#1C1F37]">
              we assign workers to carefully wait on your guests at your parties
            </h5>
          </div>
          {/* cont4 */}
          <div className="flex flex-col bg-white  rounded-lg shadow-lg border xl:border-2 border-[#C44B99] py-12 max-w-[220px] hover:-translate-y-10 duration-1000 mx-auto md:max-w-[300px] md:px-10 md:pb-8">
            <img src={serv4} alt="" className="w-14 mx-auto" />
            <h3 className="mt-8 text-xl text-[#C44B99]">Burials</h3>
            <h5 className="mt-1 max-w-[180px] mx-auto text-sm md:max-w-[300px]md:mb-0 text-[#1C1F37]">
              we assign workers to carefully wait on your guests at burial
              ceremonies
            </h5>
          </div>
        </div>
      </section>
      {/* why choose us */}
      <section className="section-center mt-40 px-4">
        <h5 className="md:text-2xl md:mb-0 text-[#C44B99]">Why choose us </h5>
        <h3 className="mt-0 text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
          Service Excellence Guarranteed
        </h3>
        {/* why1 */}
        <div className="md:flex md:flex-row-reverse md:justify-center md:items-center gap-8 lg:gap-0 ">
          {/* image */}
          <div className="mx-auto max-w-sm md:max-w-full md:w-2/3">
            <img src={hos1} alt="" />
          </div>
          {/* text */}
          <div className="md:w-1/3">
            {/* 1 */}
            <div>
              <h3 className="text-2xl lg:text-3xl md:text-left">
                Quality of Service
              </h3>
              <h5 className="md:text-left md:text-sm lg:text-base lg:max-w-xs lg:mx-0">
                Our hospitality workers provide top-quality hospitality serivce
                at all occasions so you know all your invited guests are in good
                hands
              </h5>
            </div>
            {/* 2 */}
            <div>
              <h3 className="text-2xl md:text-left lg:text-3xl md:mt-2">
                Access to hundreds of workers
              </h3>
              <h5 className="md:text-left lg:text-base md:text-sm lg:max-w-xs lg:mx-0">
                Not matter the size of your occassion , we have got you covered.
                our workforce strength is big enough for any occcasion and
                number of guests you might have.
              </h5>
            </div>
          </div>
        </div>
        {/* why2 */}
        <div className="md:flex md:flex-row md:justify-between md:items-center gap-0 lg:gap-0 mt-16 ">
          {/* image */}
          <div className="mx-auto md:mx-0 max-w-sm md:max-w-xl md:w-2/3">
            <img src={hos2} alt="" />
          </div>
          {/* text */}
          <div className="md:w-1/3">
            {/* 1 */}
            <div>
              <h3 className="text-2xl lg:text-3xl md:text-left">
                Pocket friendly prices
              </h3>
              <h5 className="md:text-left md:text-sm lg:text-base lg:max-w-sm lg:mx-0">
                We price our services with you in mind, so you do not have to
                over spend on your budget to get the quality service you
                deserve.
              </h5>
            </div>
            {/* 2 */}
            <div className="md:mb-20 lg:mb-28">
              <h3 className="text-2xl md:text-left lg:text-3xl md:mt-2">
                Great customer service
              </h3>
              <h5 className="md:text-left lg:text-base md:text-sm lg:max-w-sm lg:mx-0">
                Our feedback mechanism shows that our customer service ratings
                are high up. this shows that all efforts we put into giving our
                clients great customer service has been fruitfull and its our
                desire to build on this ratings
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/* get intouch */}
      <section className="section-center lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl xl:mt-24 px-6 ">
        <div className="relative">
          <div className="bg-[#E95F8E] px-6 py-10 rounded-md md:px-16 md:py-14 relative overflow-hidden">
            <h3 className="text-white mt-0 text-xl md:text-2xl lg:text-3xl xl:text-3xl">
              Are you ready to start your{" "}
              <span className="backimg  animate-pulse z-10">Hospitality </span>{" "}
              journey with us today? Then get in touch !
            </h3>
            {/* button */}
            <div className="mt-6 flex flex-col md:flex-row justify-center items-center gap-4 md:mt-10">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/12027057631"
                className="bg-[#1F7CFF] text-white font-alata px-4 py-1 md:py-2  rounded-md hover:bg-white hover:text-[#5E6282] border-[#1F7CFF] "
              >
                Let's Talk
              </a>
              <Link
                to={"/hospitalityform"}
                className=" border-2 text- font-alata px-4 py-1 md:py-2  rounded-md text-white hover:bg-white hover:text-[#5E6282] "
              >
                Book an Appointment
              </Link>
            </div>
            {/* spiral */}
            <img
              src={spiralt}
              alt=""
              className="absolute -right-6 -top-6 w-20  opacity-40 md:w-24 xl:w-24"
            />
            <img
              src={spiralb}
              alt=""
              className="absolute left-0 bottom-0 w-20  opacity-40 md:w-32 xl:w-44"
            />
          </div>
          <img
            src={forw}
            alt=""
            className="absolute -right-4 -top-4 w-10 xl:w-16 animate-bounce"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Hospitality;
