import React from 'react'
import form from '../Assets/form.png'

const FormHero = () => {
  return (
    <section className='section-center px-4'>
      {/* section-conainer */}
      <div className='space-y-8 md:flex  md:flex-row-reverse md:space-y-0 md:justify-between md:items-center md:gap-6 lg:gap-8'>
        {/* image */}
        <div className='max-w-xs mx-auto xl:max-w-lg  md:w-1/2'>
          <img src={form} alt='' className='w-full' />
        </div>
        {/* text */}
        <div className='space-y-4 md:w-1/2'>
          {/* text */}
          <div className='mx-auto text-center space-y-4 md:text-left md:mx-0'>
            <h1 className='font-alata font-bold text-3xl lg:text-4xl xl:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 max-w-sm lg:max-w-md text-center mx-auto md:mx-0 md:text-left'>
              We are always available 24/7 to attend to all your inquiries
            </h1>
            <h5 className='w-72 mx-auto text-center text-[#1C1F37] font-alata text-md md:text-left md:mx-0 xl:w-4/5 lg:w-80 xl:text-xl'>
              Kindly fill out the inqury form below and our customer support
              staff will definetely get back to you in little or no time
            </h5>
          </div>
        </div>
      </div>
    </section>
  )
}
export default FormHero
