import React, { useState } from "react";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import hero from "../Assets/healthcare.png";
import woman from "../Assets/seniorwoman.png";
import { FaHospitalUser } from "react-icons/fa";
import { GiHospitalCross } from "react-icons/gi";
import { RiTimerFlashFill } from "react-icons/ri";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { AiOutlineSafety } from "react-icons/ai";
import { TbFriends } from "react-icons/tb";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { GiHotMeal } from "react-icons/gi";
import { GiStumpRegrowth } from "react-icons/gi";

import { SiTrustpilot } from "react-icons/si";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { TiArrowMinimise } from "react-icons/ti";

import { HiShoppingBag } from "react-icons/hi";
import who1 from "../Assets/who1.png";
import who2 from "../Assets/who2.png";
import who3 from "../Assets/who3.png";
import who4 from "../Assets/who4.png";
import who5 from "../Assets/who5.png";
import who6 from "../Assets/who6.png";
import value1 from "../Assets/value1.png";
import spiralb from "../Assets/spiralb.png";
import spiralt from "../Assets/spiralt.png";
import forw from "../Assets/forw.png";
import Footer from "../Components/Footer";

const Healthcare = () => {
  const [pannel, setPannel] = useState("1");
  return (
    <>
      <Header />
      {/* hero */}
      <section className="section-center px-4">
        {/* container */}
        <div>
          {/* text and button */}
          <div>
            <h3 className=" mt-0 text-3xl md:text-5xl xl:text-6xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Get the special healthcare <br /> service you deserve
            </h3>
            <h5 className="md:text-xl text-[#1C1F37] lg:text-2xl">
              Give your loved ones the special healthcare service{" "}
              <br className="hidden md:block" /> they deserve in their trying
              times today !
            </h5>
            {/* button */}
            <div className="flex flex-row justify-center gap-4 items-center  mx-auto  mt-4">
              <Link
                to="/healthcareform"
                className=" px-4 md:px-8 py-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-sans font-semibold shadow-md"
              >
                Get started
              </Link>
            </div>
          </div>
          {/* image */}
          <div className="mt-6 md:mt-10 xl:max-w-3xl xl:mx-auto">
            <img src={hero} alt="" />
          </div>
        </div>
      </section>
      {/* abouts us */}
      <section className="section-center px-4">
        {/* text */}
        <div>
          <h3 className="text-3xl md:text-4xl xl:text-5xl">About us</h3>
          <h5 className="md:mb-0 md:max-w-xl">
            Detailed below is a brief explanation as to what our health packages
            entails and comprises of. Not satisfied ? Dont hesistate to contact
            us
          </h5>
          {/* floating tab */}
          <div className="mt-4 md:mt-8">
            {/* tab-headers */}
            <div className="flex flex-row justify-between items-center  md:max-w-2xl xl:max-w-4xl mx-auto   ">
              {/* tab-1 */}
              <div className="  cursor-pointer flex flex-col justify-start items-start relative">
                <h5
                  className="text-darkGrayishBlue font-bold font-alata text-xs md:text-base  xl:text-xl "
                  onClick={() => setPannel("1")}
                >
                  Brief History
                </h5>
                {pannel === "1" ? (
                  <div className="border absolute -bottom-5  border-b-2 border-[#C44B99] mx-auto mt-4   w-full  ">
                    <div></div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* tab-2 */}
              <div className="cursor-pointer flex flex-col justify-center items-center relative ">
                <h5
                  className="text-darkGrayishBlue font-bold font-alata text-xs md:text-base  xl:text-xl "
                  onClick={() => setPannel("2")}
                >
                  What we offer
                </h5>
                {pannel === "2" ? (
                  <div className="border absolute -bottom-5  border-b-2 border-[#C44B99] mx-auto mt-4   w-full "></div>
                ) : (
                  ""
                )}
              </div>
              {/* tab-3*/}
              <div className="cursor-pointer flex flex-col justify-center items-center relative">
                <h5
                  className="text-darkGrayishBlue font-bold font-alata text-xs md:text-base  xl:text-xl "
                  onClick={() => setPannel("3")}
                >
                  Who we help
                </h5>
                {pannel === "3" ? (
                  <div className="border absolute -bottom-5  border-b-2 border-[#C44B99] mx-auto mt-4   w-full "></div>
                ) : (
                  ""
                )}
              </div>
              {/* tab-4*/}
              <div className="cursor-pointer flex flex-col justify-end items-end relative">
                <h5
                  className="text-darkGrayishBlue font-bold font-alata text-xs md:text-base xl:text-xl"
                  onClick={() => setPannel("4")}
                >
                  Benefits of choosing us
                </h5>
                {pannel === "4" ? (
                  <div className="border absolute -bottom-5  border-b-2 border-[#C44B99]  mt-4   w-full"></div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="border-b border mt-4 md:max-w-2xl xl:max-w-4xl mx-auto"></div>
            {/* tab-containers */}
            <div className="mt-12  lg:mt-16 ">
              {/* benefits */}
              {pannel === "1" ? (
                <div className="  lg:flex lg:flex-row-reverse lg:justify-between lg:items-center lg:gap-8">
                  {/* img */}
                  <div className="mx-auto max-w-sm lg:mx-0 md:max-w-xl lg:w-1/2">
                    <img src={woman} alt="" className="shadow" />
                  </div>
                  {/* text */}
                  <div className="mt-8 lg:w-1/2">
                    <h5 className="lg:text-left">Share in our story</h5>
                    <h3 className=" text-2xl md:text-3xl mt-1 lg:text-left  lg:text-4xl  md:mt-1 ">
                      Our Organization History
                    </h3>
                    <h5 className="lg:text-left text-justify ">
                      Glorious Healthcare Services was founded based on Love and
                      building relationships to promote choices. Our Ultimate
                      goal is to find and hire trained professional caregivers
                      who will understand and meet unique client-family’s needs.
                      I worked as a caregiver for about 10 years. Within all
                      those years, I worked with different unique individuals.
                      Providing my services as a caregiver, I realized that
                      every single patient I came across with was always needing
                      unique care. Then I concluded that building an agency that
                      will allow clients-families make choices of client care
                      needs will give them room to leave normal despite all
                      challenges. On the other hand, caregivers will also be in
                      control of how much they want to be paid for their
                      services. Trusting God for all our decisions at Glorious
                      Healthcare, Client-family satisfaction will be reached.
                    </h5>
                  </div>
                </div>
              ) : pannel === "2" ? (
                <div className="mx-auto flex flex-col justify-center space-y-12 md:grid md:grid-cols-2 xl:grid-cols-3 md:space-y-0  ">
                  {/* cont1 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#1F7CFF] rounded-full text-center pt-[6px]">
                      <FaHospitalUser className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Respite Care
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left">
                        Planned or emergency temporary care provided to
                        caregivers of a child or adult.
                      </h5>
                    </div>
                  </div>
                  {/* cont2 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#F75C4E] rounded-full text-center pt-[6px]">
                      <GiHospitalCross className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Incontinence Care
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left">
                        A total package tailored to meet the individual needs of
                        patients with bladder and bowel problems
                      </h5>
                    </div>
                  </div>
                  {/* cont3 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#191046] rounded-full text-center pt-[6px]">
                      <RiTimerFlashFill className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Medication Reminders
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left first-letter:uppercase lowercase">
                        pROVISION OF REGULAR REMINDERS FOR ORAL MEDICATIONS OR
                        INJECTIONS WHILST MONITORING OF SIDE EFFECTS
                      </h5>
                    </div>
                  </div>
                  {/* cont4 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#FF1FB3] rounded-full text-center pt-[6px]">
                      <MdOutlineLocalLaundryService className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Laundry / Ironing
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left">
                        A packaged geared towards maitining the sanitary
                        conditions of our clients
                      </h5>
                    </div>
                  </div>
                  {/* cont5 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#5C4EF7] rounded-full text-center pt-[6px]">
                      <AiOutlineSafety className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Transfer / Safety
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left">
                        Provision of direct mobility assistance to incapcitated
                        clients in & around the house
                      </h5>
                    </div>
                  </div>
                  {/* cont6 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#979797] rounded-full text-center pt-[6px]">
                      <TbFriends className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Companionship
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left">
                        Provision of physical and interactive companionship to
                        clients
                      </h5>
                    </div>
                  </div>
                  {/* cont7 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#F7954E] rounded-full text-center pt-[6px]">
                      <BsFillHouseDoorFill className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Light Housekeeping
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left first-letter:uppercase lowercase">
                        VACUUMING, DUSTING, TRASH REMOVAL, ORGANIZING AND
                        TIDYING ROOMS & closets
                      </h5>
                    </div>
                  </div>
                  {/* cont8 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#DF947F] rounded-full text-center pt-[6px]">
                      <GiHotMeal className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Meal Preparation
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left">
                        Assistance in the preparations of meals according to
                        client specifiaction & taste
                      </h5>
                    </div>
                  </div>
                  {/* cont9 */}
                  <div className="md:flex justify-start items-start gap-4 mx-auto">
                    {/* icon */}
                    <div className="mx-auto md:mx-0 w-10 h-10 bg-[#C6ABFF] rounded-full text-center pt-[6px]">
                      <HiShoppingBag className=" text-white  text-2xl text-center mx-auto" />
                    </div>
                    {/* text */}
                    <div>
                      <h3 className="mt-0 text-xl md:text-left ">
                        Shopping / Errands
                      </h3>
                      <h5 className="mt-0  mx-0 w-[190px] md:text-left">
                        Assistance in the procurement of daily groceries and
                        household supplys
                      </h5>
                    </div>
                  </div>
                </div>
              ) : pannel === "3" ? (
                <div className=" space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-y-12 xl:grid-cols-3 xl:gap-12 mx-auto">
                  {/* content1 */}
                  <div className="flex flex-col rounded-xl bg-white max-w-xs shadow-md hover:-translate-y-5 duration-1000 mx-auto ">
                    <div className=" mx-auto">
                      {/* image */}
                      <img src={who1} alt=" " className="" />
                    </div>
                    {/* text */}
                    <div className="px-4 py-4">
                      <h3
                        className="mt-0 text-xl text-left mx-0 
                    text-[#c44b99] "
                      >
                        Client who are sick, injured, mentally or physically.
                      </h3>
                      <h5 className="text-justify font-alata text-[#1C1F37] text-sm md:mb-0">
                        If you have a love one that is sick and needs help with
                        completing their activities of daily. Our Caregivers
                        will be there for you, so you can live normal and go
                        about your business.
                      </h5>
                    </div>
                  </div>
                  {/* content2 */}
                  <div className="flex flex-col rounded-xl bg-white max-w-xs shadow-md hover:-translate-y-5 duration-1000 mx-auto  ">
                    <div className=" mx-auto">
                      {/* image */}
                      <img src={who2} alt=" " className="" />
                    </div>
                    {/* text */}
                    <div className="px-4 py-4">
                      <h3
                        className="mt-0 text-xl text-left mx-0 
                    text-[#c44b99] "
                      >
                        Fragile Seniors
                      </h3>
                      <h5 className="text-justify font-alata text-[#1C1F37] text-sm md:mb-0">
                        We all will get to a point in life when we are old and
                        can no longer do the things we use to do which included
                        shower, cooking and cleaning, laundry, shopping,
                        exercising etc. our caregivers are available for your
                        love one so they can still be happy
                      </h5>
                    </div>
                  </div>
                  {/* content3 */}
                  <div className="flex flex-col rounded-xl bg-white max-w-xs shadow-md hover:-translate-y-5 duration-1000 mx-auto  ">
                    <div className=" mx-auto">
                      {/* image */}
                      <img src={who3} alt=" " className="" />
                    </div>
                    {/* text */}
                    <div className="px-4 py-4">
                      <h3
                        className="mt-0 text-xl text-left mx-0 
                    text-[#c44b99] "
                      >
                        Disable clients
                      </h3>
                      <h5 className="text-justify font-alata text-[#1C1F37] text-sm md:mb-0">
                        People get disable from different inc- idents including
                        accidents or illness. Disability makes people unable to
                        live the live they were able to live before or do they
                        things they were able to do before. Our caregivers are
                        available so that your love one will be comfortable
                      </h5>
                    </div>
                  </div>
                  {/* content4 */}
                  <div className="flex flex-col rounded-xl bg-white max-w-xs shadow-md hover:-translate-y-5 duration-1000 mx-auto  ">
                    <div className=" mx-auto">
                      {/* image */}
                      <img src={who4} alt=" " className="" />
                    </div>
                    {/* text */}
                    <div className="px-4 py-4">
                      <h3
                        className="mt-0 text-xl text-left mx-0 
                    text-[#c44b99] "
                      >
                        Companionship
                      </h3>
                      <h5 className="text-justify font-alata text-[#1C1F37] text-sm md:mb-0">
                        Our caregivers are here to assure your love one and keep
                        them happy. Being lonely can always promote depression
                        but our caregivers are here to keep them company while
                        you go to work
                      </h5>
                    </div>
                  </div>
                  {/* content5 */}
                  <div className="flex flex-col rounded-xl bg-white max-w-xs shadow-md hover:-translate-y-5 duration-1000 mx-auto  ">
                    <div className=" mx-auto">
                      {/* image */}
                      <img src={who5} alt=" " className="" />
                    </div>
                    {/* text */}
                    <div className="px-4 py-4">
                      <h3
                        className="mt-0 text-xl text-left mx-0 
                    text-[#c44b99] "
                      >
                        Hospice clients
                      </h3>
                      <h5 className="text-justify font-alata text-[#1C1F37] text-sm md:mb-0">
                        Hospice patients sometimes go throu- gh pain and needing
                        lots of care and attention. Here at Glorious healthcare,
                        we understand your stress and challe- nges. We are
                        available so that you can take some time to care for
                        yourself.
                      </h5>
                    </div>
                  </div>
                  {/* content6 */}
                  <div className="flex flex-col rounded-xl bg-white max-w-xs shadow-md hover:-translate-y-5 duration-1000 mx-auto ">
                    <div className=" mx-auto">
                      {/* image */}
                      <img src={who6} alt=" " className="" />
                    </div>
                    {/* text */}
                    <div className="px-4 py-4">
                      <h3
                        className="mt-0 text-xl text-left mx-0 
                    text-[#c44b99] "
                      >
                        Care for Clients of all Ages
                      </h3>
                      <h5 className="text-justify font-alata text-[#1C1F37] text-sm md:mb-0">
                        At Glorious Healthcare, if there is a Ca- re need, our
                        caregivers are available to help. We take care of
                        clients of all ag- es. Please drop us a message or give
                        us a call.
                      </h5>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">
                  <h3 className="mb-12 text-[#c44b99] md:mt-4 md:text-left text-3xl md:text-4xl ">
                    Our advantage
                  </h3>
                  <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-8 xl:gap-16">
                    {/* advantage1 */}
                    <div className="flex  items-start justify-center gap-4">
                      {/* line */}
                      <div className="w-1 rounded h-16 bg-[#c44b99]"></div>
                      {/* text */}
                      <div>
                        <h3 className="mt-0 text-left text-xl lg:text-2xl">
                          We Do the Hiring
                        </h3>
                        <h5 className="text-justify mx-0">
                          We take care of the difficult part which finding
                          professionals. Our Caregivers go through a thorough
                          process in which we interview, screen and background
                          check to give you and your family a stress-free care.
                        </h5>
                      </div>
                    </div>
                    {/* advantage2 */}
                    <div className="flex  items-start justify-center gap-4">
                      {/* line */}
                      <div className="w-1 rounded h-16 bg-[#c44b99]"></div>
                      {/* text */}
                      <div>
                        <h3 className="mt-0 text-left text-xl lg:text-2xl">
                          Hands Free Payroll solution
                        </h3>
                        <h5 className="text-justify mx-0">
                          Your caregivers are paid each week using Direct
                          Deposit on your behalf based on the hours recorded
                          when they clock in and clock out.
                        </h5>
                      </div>
                    </div>
                    {/* advantage3 */}
                    <div className="flex  items-start justify-center gap-4">
                      {/* line */}
                      <div className="w-1 rounded h-16 bg-[#c44b99]"></div>
                      {/* text */}
                      <div>
                        <h3 className="mt-0 text-left text-xl lg:text-2xl max-w-xs">
                          24/7 Access to your Care Coordinator for Fill-In /
                          Replacement Care
                        </h3>
                        <h5 className="text-justify mx-0">
                          Caregiver calls in sick and none of your other
                          approved caregivers can cover it? Give us a call and a
                          Care Coordinator will begin working right away to help
                          you find a replacement.
                        </h5>
                      </div>
                    </div>
                    {/* advantage4 */}
                    <div className="flex  items-start justify-center gap-4">
                      {/* line */}
                      <div className="w-1 rounded h-16 bg-[#c44b99]"></div>
                      {/* text */}
                      <div>
                        <h3 className="mt-0 text-left text-xl lg:text-2xl max-w-xs">
                          Online Schedules & GPS Tracked Time Clock
                        </h3>
                        <h5 className="text-justify mx-0">
                          View your caregivers schedules and see their recorded
                          Clock In/Out times and any Shift Notes.
                        </h5>
                      </div>
                    </div>
                    {/* advantage5 */}
                    <div className="flex  items-start justify-center gap-4">
                      {/* line */}
                      <div className="w-1 rounded h-16 bg-[#c44b99]"></div>
                      {/* text */}
                      <div>
                        <h3 className="mt-0 text-left text-xl lg:text-2xl">
                          Contracts
                        </h3>
                        <h5 className="text-justify mx-0">
                          Family sign their care contract on our secure portal
                          saving you the drive time to concentrate on other
                          things.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* our key values */}
      <section className="section-center px-4">
        {/* conatiner */}
        <div className="xl:flex xl:justify-between xl:items-center xl:gap-16">
          {/* image */}
          <div className="w-72 mx-auto md:w-96 xl:w-1/3">
            <img src={value1} alt="" />
          </div>
          {/* text */}
          <div className="md:max-w-lg md:mx-auto lg:max-w-3xl xl:w-2/3 ">
            <h3 className="text-3xl md:text-4xl xl:text-left xl:text-4xl">
              Our key values
            </h3>
            {/* values options */}
            <div className="mt-16 space-y-12 lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-8 ">
              {/* value1 */}
              <div>
                {/* icon */}
                <div className="bg-[#c44b99] w-12 h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <SiTrustpilot className="text-white text-3xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-2xl xl:text-left">
                    Integrity
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    we hire quality professionals who are honest to the services
                    they provide. With God being our guide, we are following
                    strong moral principles. Entrust the care of your love ones
                    with us and be worry free.
                  </h5>
                </div>
              </div>
              {/* value2 */}
              <div>
                {/* icon */}
                <div className="bg-[#c44b99] w-12 h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <GiStumpRegrowth className="text-white text-3xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-2xl xl:text-left">
                    Growth
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    we plan on expanding our Caregiving service across Texas and
                    to all 50 States. We also plan to open new Franchise
                    opportunities to kind hearths who would like to join us in
                    hiring professional caregivers
                  </h5>
                </div>
              </div>
              {/* value3 */}
              <div>
                {/* icon */}
                <div className="bg-[#c44b99] w-12 h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <VscWorkspaceTrusted className="text-white text-3xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-2xl xl:text-left">
                    Respect
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    We believe in respect being the main driven factor in
                    building relationships. Here at Glorious Healthcare, our
                    goal and the goal of our caregivers is to respect and follow
                    the care choices of our client and families to achieve their
                    satisfaction
                  </h5>
                </div>
              </div>
              {/* value4 */}
              <div>
                {/* icon */}
                <div className="bg-[#c44b99] w-12 h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <TiArrowMinimise className="text-white text-3xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-2xl xl:text-left">
                    Excellence
                  </h3>
                  <h5 className="xl:text-left mt-1">
                    we are driven by excellence starting from the company
                    founders, Care-coordinators down to our professional
                    caregivers. We have a well-coordinated system that attracts
                    the best quality professional Caregivers that meet the
                    unique need of clients.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get intouch */}
      <section className="section-center lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl xl:mt-24 px-6 ">
        <div className="relative">
          <div className="bg-[#E95F8E] px-6 py-10 rounded-md md:px-16 md:py-14 relative overflow-hidden">
            <h3 className="text-white mt-0 text-xl md:text-2xl lg:text-3xl xl:text-3xl">
              Are you ready to start your{" "}
              <span className="backimg  animate-pulse z-10">Healthcare </span>{" "}
              journey with us today? Then get in touch !
            </h3>
            {/* button */}
            <div className="mt-6 flex flex-col md:flex-row justify-center items-center gap-4 md:mt-10">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/12027057631"
                className="bg-white text-[#C44B99] rounded-md py-1 md:py-2 px-4 hover:text-white hover:bg-[#1C1F37]"
              >
                Let's Talk
              </a>
              <Link
                to={"/healthcareform"}
                className="border border-white text-white rounded-md py-1 md:py-2 px-4 hover:bg-[#1C1F37] "
              >
                Book an Appointment
              </Link>
            </div>
            {/* spiral */}
            <img
              src={spiralt}
              alt=""
              className="absolute -right-6 -top-6 w-20  opacity-40 md:w-24 xl:w-24"
            />
            <img
              src={spiralb}
              alt=""
              className="absolute left-0 bottom-0 w-20  opacity-40 md:w-32 xl:w-44"
            />
          </div>
          <img
            src={forw}
            alt=""
            className="absolute -right-4 -top-4 w-10 xl:w-16 animate-bounce"
          />
        </div>
      </section>
      {/* footer */}
      <Footer />
    </>
  );
};
export default Healthcare;
