import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Transportation from "./pages/Transportation";
import Career from "./pages/Career";
import Hospitality from "./pages/Hospitality";
import Error from "./pages/Error";
import Healthcare from "./pages/Healthcare";
import HealthcareForm from "./Forms/HealthcareForm";
import HospitalityForm from "./Forms/HospitalityForm";
import CareerForm from "./Forms/CareerForm";
import GeneralForm from "./Forms/GeneralForm";
import TransportationForm from "./Forms/TransportationForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="transportation" element={<Transportation />} />
        <Route exact path="career" element={<Career />} />
        <Route exact path="hospitality" element={<Hospitality />} />
        <Route exact path="healthcareform" element={<HealthcareForm />} />
        <Route exact path="hospitalityform" element={<HospitalityForm />} />
        <Route exact path="healthcare" element={<Healthcare />} />
        <Route
          exact
          path="transportationForm"
          element={<TransportationForm />}
        />
        <Route exact path="careerForm" element={<CareerForm />} />
        <Route exact path="generalForm" element={<GeneralForm />} />

        <Route exact path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
