import axios from "axios";
import { useEffect, useState } from "react";

export default function useCountries() {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    async function getCountries() {
      try {
        const { data } = await axios.get("https://restcountries.com/v3.1/all");
        const mappedData = data
          ?.map((country) => country?.name?.common)
          .sort((a, b) => (a > b ? 1 : -1));
        setCountries(mappedData);
      } catch (error) {
        console.log(error);
      }
    }
    getCountries();
  }, []);
  return { countries };
}
