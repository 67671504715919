import axios from "axios";
import { useEffect, useState } from "react";

export default function useUserCountry() {
  const [userCountry, setUserCountry] = useState(null);
  useEffect(() => {
    async function getUserCountry() {
      try {
        const { data } = await axios.get("https://ipapi.co/json/");
        setUserCountry(data?.country_name);
      } catch (error) {
        console.log(error);
      }
    }
    getUserCountry();
  }, []);
  return { userCountry };
}
