import React, { useState } from 'react'
import Header from '../Components/Header'

import HealthcareComp from '../Components/HealthcareComp'
import HospitalityComp from '../Components/HospitalityComp'
import TransportationComp from '../Components/TransportationComp'
import FormHero from '../Components/FormHero'
import Footer from '../Components/Footer'

const GeneralForm = () => {
  const [clicked, setClicked] = useState('1')
  return (
    <>
      <Header />
      {/* hero */}
      <FormHero />
      {/* form */}
      <section className='section-center px-4'>
        {/* header */}
        <div>
          <h3 className='text-2xl md:text-3xl'>How may we assist?</h3>
          <h5>
            Please fill out the form below in details. Dont forget to specify
            the service you are inquiring about
          </h5>
        </div>
        {/* form input */}
        <div className='px-4 xl:flex xl:justify-start xl:items-start xl:gap-6'>
          {/* title */}
          <div className=' flex justify-between md:gap-14 mt-10 xl:flex-col xl:items-start xl:w-1/4'>
            {/* title 1 */}
            <div
              className='flex flex-col justify-center items-center xl:flex-row-reverse xl:gap-2 cursor-pointer '
              onClick={() => setClicked('1')}
            >
              <h3 className='text-sm md:text-lg mt-0 '>Healthcare</h3>
              {clicked === '1' ? (
                <div className='border-b-2 w-10  border-[#C44B99] mt-1 md:border-b-4 xl:border-b-0 xl:border-l-4 xl:w-2 xl:h-8 xl:mt-0'></div>
              ) : (
                ''
              )}
            </div>
            {/* title 2 */}
            <div
              className='flex flex-col justify-center items-center xl:flex-row-reverse xl:gap-2 cursor-pointer'
              onClick={() => setClicked('2')}
            >
              <h3 className='text-sm md:text-lg mt-0'>Hospitality</h3>
              {clicked === '2' ? (
                <div className='border-b-2 w-10  border-[#C44B99] mt-1 md:border-b-4 xl:border-b-0 xl:border-l-4 xl:w-2 xl:h-8 xl:mt-0'></div>
              ) : (
                ''
              )}
            </div>
            {/* title 3 */}
            <div
              className='flex flex-col justify-center items-center xl:flex-row-reverse xl:gap-2 cursor-pointer'
              onClick={() => setClicked('3')}
            >
              <h3 className='text-sm md:text-lg mt-0'>Transportation</h3>
              {clicked === '3' ? (
                <div className='border-b-2 w-10  border-[#C44B99] mt-1 md:border-b-4 xl:border-b-0 xl:border-l-4 xl:w-2 xl:h-8 xl:mt-0'></div>
              ) : (
                ''
              )}
            </div>
          </div>
          {clicked === '1' ? (
            <HealthcareComp />
          ) : clicked === '2' ? (
            <HospitalityComp />
          ) : (
            <TransportationComp />
          )}
        </div>
      </section>
      <Footer />
    </>
  )
}
export default GeneralForm
