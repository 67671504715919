import React from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { AiFillCaretUp } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const [openn, setOpenn] = React.useState(false);
  const [opennn, setOpennn] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleOpenOne = () => {
    setOpenn(!openn);
  };
  const handleOpenTwo = () => {
    setOpennn(!opennn);
  };
  return (
    // mobile footer
    <>
      <div className="bg-[#1C1F37] mx-auto pt-12 pb-8 px-2 xl:px-8 ">
        {/* containter */}
        <div className="xl:flex xl:justify-items-start  xl:items-start xl:gap-4 xl:py-10 ">
          {/* company details hours */}
          <div className="text-center mx-auto mt-6   xl:mt-0">
            <button className="font-sans font-semibold text-white text-lg flex justify-center xl:justify-start items-end gap-2 mx-auto xl:mx-0 ">
              Glorious Hospitality Service LLC
            </button>
            <div>
              {/* clock */}
              <div className="flex justify-center xl:justify-start items-start gap-2 mt-2 pt-2">
                <div>
                  <p className="text-[#E1E1E1]  font-sans font-light  max-w-xs xl:text-left ">
                    We are a global organization providing top notch service
                    across the transportation, healthcare and hospitality
                    industry
                  </p>
                </div>
              </div>
              {/* support */}
            </div>
            )
          </div>
          {/* office hours */}
          <div className="text-center mx-auto mt-6   xl:mt-0">
            <button
              className="font-sans font-semibold text-white text-lg flex justify-center xl:justify-start items-end gap-2 mx-auto xl:mx-0 "
              onClick={handleOpen}
            >
              Office Hours
              {open ? (
                <span>
                  <AiFillCaretUp className="xl:hidden" />
                </span>
              ) : (
                <span>
                  <AiFillCaretDown className="xl:hidden" />
                </span>
              )}
            </button>
            {open ? (
              <div>
                {/* clock */}
                <div className="flex justify-center xl:justify-start items-start gap-2 mt-2 pt-2">
                  {/* <BiTimeFive className='text-white text-2xl md:text-3xl' /> */}
                  <div>
                    <p className="text-[#E1E1E1]  font-sans font-light text-center ">
                      Monday-Friday <br /> 9 AM-5 PM PST
                    </p>
                  </div>
                </div>
                {/* support */}
              </div>
            ) : (
              <div className="hidden xl:block">
                {/* web */}
                <div>
                  {/* clock */}
                  <div className="flex justify-start items-start gap-2 mt-2 pt-2">
                    <div>
                      <p className="text-[#E1E1E1] font-light font-sans ">
                        Monday-Friday <br /> 9 AM-5 PM PST
                      </p>
                    </div>
                  </div>
                  {/* support */}
                  {/* <div className='flex justify-start items-start gap-2 mt-2'>
                    <div>
                      <p className='text-[#E1E1E1] font-light  font-sans text-left'>
                        24/7 <br /> 365 days/year
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            )}
          </div>
          {/* Contact info */}
          <div className="text-center mx-auto mt-6  xl:mt-0">
            <button
              className="font-sans font-semibold text-white text-lg flex justify-center items-end gap-2 mx-auto xl:mx-0 "
              onClick={handleOpenOne}
            >
              Contact info
              {openn ? (
                <span>
                  <AiFillCaretUp className="xl:hidden" />
                </span>
              ) : (
                <span>
                  <AiFillCaretDown className="xl:hidden" />
                </span>
              )}
            </button>
            {openn ? (
              <div>
                {/* email */}
                <div className="flex justify-center xl:justify-start items-start gap-2 mt-2 xl:pt-2 ">
                  {/* <MdEmail className='text-white text-2xl md:text-3xl md:hidden xl:block' /> */}
                  <div>
                    <p className="text-[#E1E1E1] font-sans font-light ">
                      info@glorioushealth.com
                    </p>
                  </div>
                </div>
                {/* support */}
                <div className="flex justify-center xl:justify-start items-start gap-2 mt-2">
                  {/* <FiPhoneForwarded className='text-white text-2xl md:text-3xl' /> */}
                  <div>
                    <p className="text-[#E1E1E1] font-sans font-light text-center">
                      +447 223 44456
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="hidden xl:block">
                {/* email */}
                <div className="flex justify-start items-start gap-2 mt-2 xl:pt-2">
                  <div>
                    <p className="text-[#E1E1E1]   font-sans ">
                      info@glorioushealth.com
                    </p>
                  </div>
                </div>
                {/* support */}
                <div className="flex justify-start items-start gap-2 mt-2">
                  {/* <FiPhoneForwarded className='text-white text-2xl md:text-3xl' /> */}
                  <div>
                    <p className="text-[#E1E1E1] font-light  font-sans text-left">
                      +447 223 44456
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Our Services */}
          <div className="text-center mx-auto mt-6  xl:mt-0">
            <button
              className="font-sans font-semibold text-white text-lg flex justify-center items-end gap-2 mx-auto xl:mx-0 xl:mb-2 "
              onClick={handleOpenTwo}
            >
              Our Services
              {opennn ? (
                <span>
                  <AiFillCaretUp className="xl:hidden" />
                </span>
              ) : (
                <span>
                  <AiFillCaretDown className="xl:hidden" />
                </span>
              )}
            </button>
            {opennn ? (
              <div className="flex flex-col  xl:text-left space-y-2 font-sans pt-3 xl:pt-2 font-light text-[#E1E1E1] ">
                {/* services */}
                <Link to="/hospitality">Hospitality Service</Link>
                <Link to="/healthcare">Healthcare Service</Link>
                <Link to="/transportation">Transportation Service</Link>
                <Link to="/career">Career Opportunity</Link>
              </div>
            ) : (
              <div className="hidden xl:flex xl:flex-col xl:text-left text-[#E1E1E1] xl:pt-2 space-y-2 font-sans">
                {/* services */}
                <Link to="/hospitality">Hospitality Service</Link>
                <Link to="/healthcare">Healthcare Service</Link>
                <Link to="/transportation">Transportation Service</Link>
                <Link to="/career">Career Opportunity</Link>
              </div>
            )}
          </div>
        </div>

        {/* last info */}
        <div className="xl:mt-6">
          <p className="text-white text-center mt-6 font-sans font-semibold">
            © 2022 Glorious Hospitality Service LLC <br className="md:hidden" />{" "}
            - All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
