export default function CheckInputForm({ title, field, setField, mainField }) {
  const handleClick = (e) => {
    const { id } = e.currentTarget;
    if (id === "yes") {
      console.log(id);
      setField((prev) => ({
        ...prev,
        personal: { ...prev.personal, [field]: true },
      }));
      console.log(mainField);
      return;
    }
    return setField((prev) => ({
      ...prev,
      personal: { ...prev.personal, [field]: false },
    }));
  };
  return (
    <div className="space-y-2">
      <h5 className="mx-0 text-left max-w-lg text-black">{title}</h5>
      <div
        id="yes"
        onClick={handleClick}
        className="flex flex-row justify-start w-max cursor-pointer items-center gap-4  "
      >
        <input
          checked={mainField?.personal?.[field] === true ? true : false}
          name="yes"
          type="Checkbox"
          className=""
        />
        <label htmlFor="yes" className="font-alata text-lg">
          yes
        </label>
      </div>
      <div
        onClick={handleClick}
        id="no"
        className="flex w-max cursor-pointer flex-row justify-start items-center gap-4  "
      >
        <input
          checked={mainField?.personal?.[field] === false ? true : false}
          name="no"
          type="Checkbox"
          className=""
        />
        <label htmlFor="no" className="font-alata text-lg">
          No
        </label>
      </div>
    </div>
  );
}
