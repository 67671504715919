import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import trans from "../Assets/trans.png";
import trans1 from "../Assets/trans1.png";
import trans2 from "../Assets/trans2.png";
import trans3 from "../Assets/trans3.png";
import trans4 from "../Assets/trans4.png";
import { Link } from "react-router-dom";
import spiralb from "../Assets/spiralb.png";
import spiralt from "../Assets/spiralt.png";
import forw from "../Assets/forw.png";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdOutlineEmojiTransportation } from "react-icons/md";
import { MdOutlineMonitor } from "react-icons/md";

const Transportation = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <section className="section-center px-4">
        {/* container */}
        <div>
          {/* text and button */}
          <div>
            <h3 className=" mt-0 text-3xl md:text-5xl xl:text-6xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Lets get your from point A to <br className="hidden lg:block" />{" "}
              point B in record time
            </h3>
            <h5 className="md:text-xl text-[#1C1F37] lg:text-2xl  ">
              Transporting an ill person or moving a package from{" "}
              <br className="hidden lg:block" /> one point to another? Then lets
              pick them up !
            </h5>
            {/* button */}
            <div className="flex flex-row justify-center gap-4 items-center  mx-auto  mt-8">
              <Link
                to="/transportationForm"
                className=" px-4 md:px-8 py-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-sans font-semibold shadow-md"
              >
                Get started
              </Link>
            </div>
          </div>
          {/* image */}
          <div className="mt-12 md:mt-20 mx-auto ">
            <img
              src={trans}
              alt=""
              className=" md:max-w-xl xl:max-w-4xl   mx-auto "
            />
          </div>
        </div>
      </section>
      {/* our services */}
      <section className="section-center px-4 ">
        <div className="xl:mt-32 mb-10  xl:mb-20">
          <h5 className="md:text-2xl md:mb-0 text-[#C44B99] ">Our Services </h5>
          <h3 className="mt-0 text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
            We the offer following Services
          </h3>
          {/* container */}
          <div className="space-y-8 md:space-y-12 md:mt-14 mt-10">
            {/* info 1 */}
            <div className="md:flex md:justify-center md:gap-4 xl:gap-12">
              {/* image */}
              <div className="max-w-xs md:w-1/2 mx-auto xl:max-w-sm md:mx-0">
                <img
                  src={trans1}
                  alt=""
                  className="w-full hover:scale-y-110 duration-1000"
                />
              </div>
              {/* text */}
              <div className="md:w-1/2 lg:w-auto ">
                {/* header */}
                <div>
                  {/* text */}
                  <h3 className="mt-3 md:text-left text-2xl  ">
                    Trucking Service
                  </h3>
                  {/* underline */}
                  <div className="border-b-4 w-44 border-[#C44B99] mx-auto mt-2 md:mx-0 md:w-24  "></div>
                </div>
                {/* textfull */}
                <h5 className="md:text-left xl:max-w-sm xl:mx-0 lg:w-72 xl:w-80 lg:mx-0">
                  Our trucking service is designed to help you transport your
                  packages to any desired location of your choice . we have our
                  starting point at huston Texas and we move your packages to
                  any place of your choice in the united states of America.
                  Planning on relocationg or moving heavy packages ? Then
                  contract us to do the heavy lifting for you today!
                </h5>
              </div>
            </div>
            {/* info 2 */}
            <div className="md:flex md:flex-row-reverse md:justify-center md:gap-4 xl:gap-12">
              {/* image */}
              <div className="max-w-xs md:w-1/2 mx-auto xl:max-w-sm md:mx-0">
                <img
                  src={trans2}
                  alt=""
                  className="w-full hover:scale-y-110 duration-1000"
                />
              </div>
              {/* text */}
              <div className="md:w-1/2 lg:w-auto ">
                {/* header */}
                <div>
                  {/* text */}
                  <h3 className="mt-3 md:text-left text-2xl  ">
                    Medical Transportation
                  </h3>
                  {/* underline */}
                  <div className="border-b-4 w-44 border-[#C44B99] mx-auto mt-2 md:mx-0 md:w-24  "></div>
                </div>
                {/* textfull */}
                <h5 className="md:text-left xl:max-w-sm xl:mx-0 lg:w-72 xl:w-80 lg:mx-0">
                  Our medical transportation service helps people who are
                  incapcitated or people who have loved ones that are
                  incapacitated to move them or their loved ones to medical
                  facililties in cases of routine check ups, medical emergencies
                  and other scenerios. You can bank on you us to be there on
                  time and with the right instruments to save the day.
                </h5>
              </div>
            </div>
            {/* info 3 */}
            <div className="md:flex md:justify-center md:gap-4 xl:gap-12">
              {/* image */}
              <div className="max-w-xs md:w-1/2 mx-auto xl:max-w-sm md:mx-0">
                <img
                  src={trans3}
                  alt=""
                  className="w-full hover:scale-y-110 duration-1000"
                />
              </div>
              {/* text */}
              <div className="md:w-1/2 lg:w-auto ">
                {/* header */}
                <div>
                  {/* text */}
                  <h3 className="mt-3 md:text-left text-2xl  ">
                    Package Delivery
                  </h3>
                  {/* underline */}
                  <div className="border-b-4 w-44 border-[#C44B99] mx-auto mt-2 md:mx-0 md:w-24  "></div>
                </div>
                {/* textfull */}
                <h5 className="md:text-left xl:max-w-sm xl:mx-0 lg:w-72 xl:w-80 lg:mx-0">
                  We also run mini-delivery within the inner cities , so you
                  dont have to worry about geting that package to its intended
                  location. We will handle the heavy liftings . So you can go
                  ahead and make those orders today and be rest assured that we
                  will deliver them to you or your intended target in due time
                  and in safe conditions.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* our advantage */}
      <section className="section-center px-4">
        <h5 className="md:text-2xl md:mb-0 text-[#C44B99] ">Our Advantage </h5>
        <h3 className="mt-0 text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
          We Deliver as Promised
        </h3>
        {/* container */}
        <div className="md:flex md:justify-between md:items-center xl:items-end md:gap-10 lg:gap-16 md:flex-row-reverse mt-16 md:mt-4">
          {/* image */}
          <div className="mx-auto max-w-xs md:max-w-1/2 xl:max-w-3xl">
            <img src={trans4} alt="" className="w-full" />
          </div>
          {/* text */}
          <div className="mt-12 md:w-1/2 ">
            <h5 className="md:text-left text-[#C44B99]">why choose us?</h5>
            <h3 className=" text-3xl mt-3 md:text-left md:text-xl lg:text-3xl md:mt-1 ">
              Our Advantages
            </h3>
            <h5 className="w-72 mx-auto text-center text-[#1C1F37] font-alata text-md md:text-left md:mx-0 lg:w-full  xl:text-xl">
              Transporting with Glorious Hospitality Transportation services
              guarrentees you 100% delivery on all packages . we have the
              following advantages over our competitors
            </h5>
            {/* content1 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4">
              <div className="bg-[#C44B99] w-12 h-12 rounded-md px-3  py-3 mx-auto text-center md:mx-0">
                <FaMoneyCheckAlt className="text-white text-bold text-center text-xl mx-auto" />
              </div>
              <div>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  Pocket friendly cost on all delivery packages and
                  transportation services
                </h5>
              </div>
            </div>
            {/* content22 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4 md:mt-2">
              <div className="bg-[#C44B99] w-12 h-12 rounded-md px-3 py-3 mx-auto text-center md:mx-0">
                <MdOutlineEmojiTransportation className="text-white text-bold text-center text-xl mx-auto" />
              </div>
              <div>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  We offer safe handling and transportation of all packages and
                  persons
                </h5>
              </div>
            </div>
            {/* content33 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4 md:mt-2">
              <div className="bg-[#C44B99] w-12 h-12 rounded-md px-3 py-3 mx-auto text-center md:mx-0">
                <MdOutlineMonitor className="text-white text-bold text-center text-xl mx-auto" />
              </div>
              <div>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  We offer real time tracking of all packages and persons and
                  lots more
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get intouch */}
      <section className="section-center lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl xl:mt-24 px-6 ">
        <div className="relative">
          <div className="bg-[#E95F8E] px-6 py-10 rounded-md md:px-16 md:py-14 relative overflow-hidden">
            <h3 className="text-white mt-0 text-xl md:text-2xl lg:text-3xl xl:text-3xl">
              Are you ready to start your{" "}
              <span className="backimg  animate-pulse z-10">
                Transportation{" "}
              </span>{" "}
              journey with us today? Then get in touch !
            </h3>
            {/* button */}
            <div className="mt-6 flex flex-col md:flex-row justify-center items-center gap-4 md:mt-10">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/12027057631"
                className="bg-[#1F7CFF] text-white font-alata px-4 py-1 md:py-2  rounded-md hover:bg-white hover:text-[#5E6282] border-[#1F7CFF] "
              >
                Let's Talk
              </a>
              <Link
                to={"/transportationForm"}
                className=" border-2 text- font-alata px-4 py-1 md:py-2  rounded-md text-white hover:bg-white hover:text-[#5E6282] "
              >
                Book an Appointment
              </Link>
            </div>
            {/* spiral */}
            <img
              src={spiralt}
              alt=""
              className="absolute -right-6 -top-6 w-20  opacity-40 md:w-24 xl:w-24"
            />
            <img
              src={spiralb}
              alt=""
              className="absolute left-0 bottom-0 w-20  opacity-40 md:w-32 xl:w-44"
            />
          </div>
          <img
            src={forw}
            alt=""
            className="absolute -right-4 -top-4 w-10 xl:w-16 animate-bounce"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Transportation;
