import React, { useEffect } from "react";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import { TransportationFormAPI } from "../api/api";
import useUserCountry from "../hooks/useUserCountry";
import {
  resetErrors,
  setErrorField,
  validateFields,
} from "../utils/validation";

const TransportationComp = () => {
  const [loading, setLoading] = useState(false);
  const { userCountry } = useUserCountry();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    contact_number: "",
    pickup_location: "",
    delivery_location: "",
    message: "",
    country: "",
  });

  const [formDataErrors, setFormDataErrors] = useState({
    firstnameError: "",
    lastnameError: "",
    emailError: "",
    contact_numberError: "",
    pickup_locationError: "",
    delivery_locationError: "",
    messageError: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    return setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    resetErrors(formDataErrors, [], setFormDataErrors);
    const errorList = validateFields(formData);
    if (errorList.length > 0) {
      return setErrorField(errorList, formDataErrors, setFormDataErrors);
    }
    const payload = {
      firstname: formData?.firstname,
      lastname: formData?.lastname,
      email: formData?.email,
      phone: formData?.contact_number,
      pickup_location: formData?.pickup_location,
      delivery_location: formData?.delivery_location,
      message: formData?.message,
      country: formData?.country,
    };
    setLoading(true);
    const results = await TransportationFormAPI(payload);
    if (results?.success === true) {
      toast.success("Form Submitted Successfuly!", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      resetErrors(formData, [], setFormData);
      return setLoading(false);
    }
    toast.error(
      results?.error?.join("") ?? "Something went Wrong, Kindly Retry",
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
    return setLoading(false);
  };
  useEffect(() => {
    setFormData((prev) => ({ ...prev, country: userCountry }));
  }, [userCountry]);
  return (
    <div className="mt-8 md:mt-16 xl:mt-10 xl:w-3/4 xl:mx-auto">
      <form
        onSubmit={handleSubmit}
        className=" space-y-4 mx-auto lg:space-y-8 "
      >
        {/* first container */}
        <div className="lg:flex justify-between items-center lg:space-x-10 lg:space-y-0 space-y-4">
          {/* first name */}
          <div className="flex flex-col space-y-2 lg:w-1/2 ">
            <label htmlFor="firstname" className="font-alata text-lg">
              First Name
            </label>
            <input
              type="text"
              name="firstname"
              value={formData?.firstname}
              onChange={handleChange}
              placeholder="Please Input your First Name"
              className={`font-alata border ${
                formDataErrors?.firstnameError &&
                "ring-2 rounded-lg ring-red-600"
              } px-2 py-2 text-sm max-w-2xl outline-[#C44B99]`}
            />
            {formDataErrors.firstnameError && (
              <span className="text-sm font-medium text-red-600">
                {formDataErrors?.firstnameError}
              </span>
            )}
          </div>
          {/* last name */}
          <div className="flex flex-col space-y-2 lg:w-1/2 ">
            <label htmlFor="firstname" className="font-alata text-lg">
              Last Name
            </label>
            <input
              type="text"
              name="lastname"
              value={formData?.lastname}
              onChange={handleChange}
              placeholder="Please Input your Last Name"
              className={`font-alata border ${
                formDataErrors?.lastnameError &&
                "ring-2 rounded-lg ring-red-600"
              } px-2 py-2 text-sm max-w-2xl outline-[#C44B99]`}
            />
            {formDataErrors.lastnameError && (
              <span className="text-sm font-medium text-red-600">
                {formDataErrors?.lastnameError}
              </span>
            )}
          </div>
        </div>
        {/* second-container */}
        <div className="lg:flex justify-between items-center lg:space-x-10 lg:space-y-0 space-y-4">
          {/* Email */}
          <div className="flex flex-col space-y-2 lg:w-1/2 ">
            <label htmlFor="email" className="font-alata text-lg">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              placeholder="Please Input your Email Address here"
              className={`font-alata border ${
                formDataErrors?.emailError && "ring-2 rounded-lg ring-red-600"
              } px-2 py-2 text-sm max-w-2xl outline-[#C44B99]`}
            />
            {formDataErrors.emailError && (
              <span className="text-sm font-medium text-red-600">
                {formDataErrors?.emailError}
              </span>
            )}
          </div>
          {/* Contact number */}
          <div className="flex flex-col space-y-2 lg:w-1/2  ">
            <label htmlFor="Contact Number" className="font-alata text-lg">
              Contact Number
            </label>
            <input
              type="Number"
              name="contact_number"
              value={formData?.contact_number}
              onChange={handleChange}
              placeholder="Please Input your Contact Number here"
              className={`font-alata border ${
                formDataErrors?.contact_numberError &&
                "ring-2 rounded-lg ring-red-600"
              } px-2 py-2 text-sm max-w-2xl outline-[#C44B99]`}
            />
            {formDataErrors.contact_numberError && (
              <span className="text-sm font-medium text-red-600">
                {formDataErrors?.contact_numberError}
              </span>
            )}
          </div>
        </div>
        {/* third container */}
        <div className="lg:flex justify-between items-center lg:space-x-10 xl:items-end lg:space-y-0 space-y-4">
          {/* Pick up location */}
          <div className="flex flex-col space-y-2  lg:w-1/2 ">
            <label htmlFor="health attendant" className="font-alata text-lg">
              Pick up location
            </label>
            <input
              type="text"
              name="pickup_location"
              value={formData?.pickup_location}
              onChange={handleChange}
              placeholder="Add a Pickup Location/Address"
              className={`font-alata border ${
                formDataErrors?.pickup_locationError &&
                "ring-2 rounded-lg ring-red-600"
              } px-2 py-2 text-sm max-w-2xl outline-[#C44B99]`}
            />
            {formDataErrors.pickup_locationError && (
              <span className="text-sm font-medium text-red-600">
                {formDataErrors?.pickup_locationError}
              </span>
            )}
          </div>
          {/* Delivery location*/}
          <div className="flex flex-col space-y-2  lg:w-1/2  ">
            <label htmlFor="How soon" className="font-alata text-lg">
              Delivery Location
            </label>
            <input
              type="text"
              name="delivery_location"
              value={formData?.delivery_location}
              onChange={handleChange}
              placeholder="Where do you want it delivered ? "
              className={`font-alata border ${
                formDataErrors?.delivery_locationError &&
                "ring-2 rounded-lg ring-red-600"
              } px-2 py-2 text-sm max-w-2xl outline-[#C44B99]`}
            />
            {formDataErrors.delivery_locationError && (
              <span className="text-sm font-medium text-red-600">
                {formDataErrors?.delivery_locationError}
              </span>
            )}
          </div>
        </div>

        {/* message */}
        <div className="flex flex-col space-y-2 ">
          <label htmlFor="message" className="font-alata text-lg">
            Additional Message
          </label>
          <textarea
            type="text"
            name="message"
            value={formData?.message}
            onChange={handleChange}
            placeholder="Input additional message here"
            className={`font-alata border ${
              formDataErrors?.messageError && "ring-2 rounded-lg ring-red-600"
            } px-2 py-2 text-sm resize-none outline-[#C44B99] h-32`}
          ></textarea>
          {formDataErrors.messageError && (
            <span className="text-sm font-medium text-red-600">
              {formDataErrors?.messageError}
            </span>
          )}
        </div>
        {/* submit button */}
        <button
          type="submit"
          className="mx-auto w-32 text-center px-6 rounded-md shadow-md text-white py-2.5 bg-[#C44B99] font-sans flex justify-center"
        >
          {loading ? <FaSpinner className="animate-spin" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};
export default TransportationComp;
