import React from "react";
import "tw-elements";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import nurse from "../Assets/nurse.png";
import delivery from "../Assets/delivery.png";
import maid from "../Assets/maid.png";
import cursor from "../Assets/cursor.png";
import service1 from "../Assets/service1.png";
import service2 from "../Assets/service2.png";
import service3 from "../Assets/service3.png";
import test1 from "../Assets/test1.png";
import test2 from "../Assets/test2.png";
import test3 from "../Assets/test3.png";
import test4 from "../Assets/test4.png";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { BsPersonCircle } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { MdMailOutline, MdSettings } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { GiCash } from "react-icons/gi";
import curve1 from "../Assets/curve1.png";
import curve2 from "../Assets/curve2.png";
import why from "../Assets/why.png";

const Home = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <section className="section-center px-4">
        {/* section-conainer */}
        <div className="space-y-8 md:flex  md:flex-row-reverse md:space-y-0 md:justify-between md:items-center md:gap-6 lg:gap-8">
          {/* new image */}
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide relative md:w-1/2"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner relative w-full overflow-hidden">
              <div class="carousel-item active relative float-left w-full">
                <img src={nurse} class="w-full" alt="Wild Landscape" />
              </div>
              <div class="carousel-item relative float-left w-full">
                <img src={maid} class="w-full" alt="Camera" />
              </div>
              <div class="carousel-item relative float-left w-full">
                <img src={delivery} class="w-full" alt="Exotic Fruits" />
              </div>
            </div>
          </div>

          {/* text */}
          <div className="space-y-4 md:w-1/2">
            {/* cursor */}
            <div className="flex justify-center p-1 md:p-2 items-center gap-3 border border-[#C44B99] w-72 font-alata mx-auto relative md:mx-0">
              <img src={cursor} alt="" className="animate-spin" />
              <h5 className="mt-0 md:mb-0">You are extremely welcomed to</h5>
              <div className="absolute  -top-2 -left-2 w-3 h-3 bg-[#C44B99] rounded-full"></div>
              <div className="absolute  -top-2 -right-2 w-3 h-3 bg-[#C44B99] rounded-full"></div>
              <div className="absolute  -bottom-2 -left-2 w-3 h-3 bg-[#C44B99] rounded-full"></div>
              <div className="absolute  -bottom-2 -right-2 w-3 h-3 bg-[#C44B99] rounded-full"></div>
            </div>
            {/* text */}
            <div className="mx-auto text-center space-y-4 md:text-left md:mx-0">
              <h1 className="font-alata font-bold text-5xl xl:6xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 ">
                Glorious <br />
                Hospitality <br /> Services LLC
              </h1>
              <h5 className="w-72 mx-auto text-center text-[#1C1F37] font-alata text-md md:text-left md:mx-0 md:w-10/12 lg:w-11/12 xl:text-xl">
                We are a one stop destination for all you will ever need in the
                <span className="text-[#F57059] font-semibold">
                  {" "}
                  Healthcare
                </span>
                ,{" "}
                <span className="text-[#186FD7] font-semibold">
                  Transportation{" "}
                </span>
                and{" "}
                <span className="text-[#05796B] font-semibold">
                  Hospitality
                </span>{" "}
                industry
              </h5>
            </div>
            {/* button */}
            <div className="flex flex-row justify-center gap-4 items-center  mx-auto md:mx-0 md:justify-start ">
              <Link
                to="/career"
                className="px-6 md:px-8 py-2 rounded-full bg-white text-[#1C1F37]  font-sans  font-semibold border shadow-md border-[#C44B99] hover:text-white hover:bg-[#1C1F37] hover:border-0"
              >
                Join us
              </Link>
              <Link
                to="/generalForm"
                className=" px-4 md:px-6 py-2  rounded-full hover:bg-[#1C1F37] bg-gradient-to-r from-purple-500 to-pink-500 text-white font-sans font-semibold shadow-md  "
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* our services */}
      <section className="section-center px-4">
        {/* text */}
        <div>
          <h3 className="text-3xl md:text-4xl xl:text-5xl">Our Services</h3>
          <h5 className="md:mb-0 md:max-w-xl">
            Our services vary across various industries including the
            healthcare, hospitality and transportation industry
          </h5>
        </div>
        {/* image-content */}
        <div className="md:flex md:justify-center md:items-center md:space-x-4 lg:space-x-6 md:-mt-16">
          {/* content 1 */}
          <div className="flex flex-col space-y-6 mt-10 md:mt-0 ">
            {/* image */}
            <div>
              <img src={service1} alt="" className="w-80 mx-auto md:mx-0" />
            </div>
            {/* txt */}
            <div className="space-y-2">
              <h3 className="mt-0 text-2xl md:text-left ">Healthcare</h3>
              <h5 className="mt-0 md:text-left md:mx-0 md:max-w-sm">
                We provide healthcare services to elderly & incapcitated paients{" "}
              </h5>
              <Link to="/healthcare">
                <div className="flex items-center justify-center mx-auto text-[#C44B99] mt-1 duration-1000 md:mx-0 md:justify-start">
                  <p className="font-sans font-medium"> Explore more</p>
                  <IoIosArrowForward className="" />
                </div>
              </Link>
            </div>
            {/* link */}
          </div>
          {/* content 2 */}
          <div className="flex flex-col space-y-6 mt-10 md:pt-20 ">
            {/* image */}
            <div>
              <img src={service2} alt="" className="w-80 mx-auto md:mx-0" />
            </div>
            {/* txt */}
            <div className="space-y-2">
              <h3 className="mt-0 text-2xl md:text-left ">Transportation</h3>
              <h5 className="mt-0 md:text-left md:mx-0 md:max-w-sm">
                We provide both medical and trucking transportation services
              </h5>
              <Link to="/transportation">
                <div className="flex items-center justify-center mx-auto text-[#C44B99] mt-1 duration-1000 md:mx-0 md:justify-start">
                  <p className="font-sans font-medium"> Explore more</p>
                  <IoIosArrowForward className="" />
                </div>
              </Link>
            </div>
            {/* link */}
          </div>
          {/* content 3 */}
          <div className="flex flex-col space-y-6 mt-10 md:pt-40 ">
            {/* image */}
            <div>
              <img src={service3} alt="" className="w-80 mx-auto md:mx-0" />
            </div>
            {/* txt */}
            <div className="space-y-2">
              <h3 className="mt-0 text-2xl md:text-left ">Hospitality</h3>
              <h5 className="mt-0 md:text-left md:mx-0 md:max-w-sm">
                We provide competent workfore skilled in hospitlity management
                services
              </h5>
              <Link to="/hospitality">
                <div className="flex items-center justify-center mx-auto text-[#C44B99] mt-1 duration-1000 md:mx-0 md:justify-start">
                  <p className="font-sans font-medium"> Explore more</p>
                  <IoIosArrowForward className="" />
                </div>
              </Link>
            </div>
            {/* link */}
          </div>
        </div>
      </section>
      {/* how to get started */}
      <section className="section-center px-4">
        {/* started */}
        <div>
          <h3 className="text-3xl md:text-4xl xl:text-5xl">
            How to get started
          </h3>
          <h5 className="md:mb-0 md:max-w-xl">
            Navigating through our website is quite easy and straight-forward
            follow the outlined steps below
          </h5>
          {/* display */}
          <div className="md:flex md:justify-center md:items-start mt-20 md:mt-24">
            {/* content1 */}
            <div className="flex flex-col space-y-6 mt-10 md:mt-0 ">
              {/* image */}
              <div>
                <div className="relative">
                  <BsPersonCircle className=" text-[#C44B99] text-7xl mx-auto bg-white shadow-lg rounded-full px-6 py-6 w-24 h-24 md:w-32 md:h-32" />
                  <p className="absolute -top-2 left-32 w-8 h-8 bg-[#C44B99] text-white text-center rounded-full mx-auto px-2 py-1 font-bold animate-pulse">
                    1
                  </p>
                </div>
              </div>
              {/* txt */}
              <div className="space-y-2">
                <h3 className="mt-0 text-2xl  ">Select a service</h3>
                <h5 className="mt-0  md:mx-0 md:max-w-sm">
                  Go through the list of services we offer and select any that
                  picks your interest
                </h5>
              </div>
            </div>
            {/* curve1 */}
            <div className="mt-16">
              <img src={curve1} alt="" className="hidden md:block" />
            </div>
            {/* content2 */}
            <div className="flex flex-col space-y-6 mt-10 md:mt-0 ">
              {/* image */}
              <div>
                <div className="relative">
                  <MdSettings className=" text-[#C44B99] text-lg mx-auto bg-white shadow-lg rounded-full px-6 py-6 w-24 h-24 md:w-32 md:h-32 " />
                  <p className="absolute -top-2 left-32 w-8 h-8 bg-[#C44B99] text-white text-center rounded-full mx-auto px-2 py-1 font-bold ">
                    2
                  </p>
                </div>
              </div>
              {/* txt */}
              <div className="space-y-2">
                <h3 className="mt-0 text-2xl  ">Complete the setup</h3>
                <h5 className="mt-0 md:mx-0 md:max-w-sm">
                  Fill out the setup form provided for each particular service
                </h5>
              </div>
            </div>
            {/* curve2 */}
            <div className="mt-16">
              <img src={curve2} alt="" className="hidden md:block" />
            </div>
            {/* content3 */}
            <div className="flex flex-col space-y-6 mt-10 md:mt-0 ">
              {/* image */}
              <div>
                <div className="relative">
                  <MdMailOutline className=" text-[#C44B99] text-7xl mx-auto bg-white shadow-lg rounded-full px-6 py-6 w-24 h-24 md:w-32 md:h-32" />
                  <p className="absolute -top-2 left-32 w-8 h-8 bg-[#C44B99] text-white text-center rounded-full mx-auto px-2 py-1 font-bold ">
                    3
                  </p>
                </div>
              </div>
              {/* txt */}
              <div className="space-y-2">
                <h3 className="mt-0 text-2xl  ">Expect our reply</h3>
                <h5 className="mt-0  md:mx-0 md:max-w-sm">
                  We will contact you as soon as possible to give you an
                  adequate feedback
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* why choose us */}
      <section className="section-center px-4">
        {/* container */}
        <div className="md:flex md:justify-between md:items-center xl:items-end md:gap-4 lg:gap-6 md:flex-row-reverse">
          {/* image */}
          <div className="mx-auto max-w-xs md:max-w-1/2 xl:max-w-3xl">
            <img src={why} alt="" className="w-full" />
          </div>
          {/* text */}
          <div className="mt-12 md:w-1/2">
            <h5 className="md:text-left">why choose us?</h5>
            <h3 className=" text-3xl mt-3 md:text-left md:text-xl lg:text-3xl md:mt-1 ">
              We guarrante the quality <br className="md:hidden" /> of our
              delivarables
            </h3>
            {/* content1 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4">
              <div className="bg-yellow-500 w-12 h-12 rounded-md px-3  py-3 mx-auto text-center md:mx-0">
                <AiOutlineStar className="text-white text-bold text-center text-xl mx-auto" />
              </div>
              <div>
                <h3 className="mt-2 text-2xl md:text-lg  md:text-left md:mt-0">
                  Quality of Service
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  We continously monitor the quality of our serv ice in order to
                  ensure we provide the best services geared towards meeting
                  your expectations and guarranteeing your satisfaction
                </h5>
              </div>
            </div>
            {/* content22 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4 md:mt-2">
              <div className="bg-pink-500 w-12 h-12 rounded-md px-3 py-3 mx-auto text-center md:mx-0">
                <TbTruckDelivery className="text-white text-bold text-center text-xl mx-auto" />
              </div>
              <div>
                <h3 className="mt-2 text-2xl md:text-lg  md:text-left md:mt-0">
                  Fast Delivery
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  We take great importance in delivering excellent service in a
                  timely and precise fashion
                </h5>
              </div>
            </div>
            {/* content33 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4 md:mt-2">
              <div className="bg-slate-600 w-12 h-12 rounded-md px-3 py-3 mx-auto text-center md:mx-0">
                <GiCash className="text-white text-bold text-center text-xl mx-auto" />
              </div>
              <div>
                <h3 className="mt-2 text-2xl md:text-lg  md:text-left md:mt-0">
                  Pocket Friendly
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  We price our products with your interest in mind thereby
                  ensuring that you dont have to spend beyond your budget to
                  enjoy our services.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimony */}
      <section className="section-center px-2">
        <h3 className="text-3xl md:text-4xl xl:text-5xl">
          Trusted by Families <br className="hidden md:block" /> & Coperations
        </h3>
        <h5 className="md:mb-0 md:max-w-sm xl:max-w-md">
          Don't just take our word for it, check out the reviews of our
          satisfied clients
        </h5>
        {/* new carousel */}
        <div
          id="carouselExampleIndicators"
          class="carousel slide relative carousel-dark mt-16 mb-32"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators absolute right-0 -bottom-16 left-0 flex justify-center p-0 mb-4">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div class="carousel-inner relative w-full overflow-hidden">
            <div class="carousel-item active float-left w-full">
              {/* single-card 1 */}
              <div className=" bg-white shadow border-2 border-[#C44B99] rounded-lg px-4 py-4  max-w-xs mx-auto md:max-w-md xl:max-w-lg ">
                {/* avatar */}
                <div className="flex justify-start items-center gap-4">
                  <div className="w-16 md:w-24">
                    <img src={test1} alt="" />
                  </div>
                  <div className="space-y-1">
                    <h3 className="text-lg mt-0 mb-0 md:text-left">
                      Micheal Cooper
                    </h3>
                    <h5 className="mt-0 text-left text-[#C44B99]">Tx USA</h5>
                    <div className="flex">
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                    </div>
                  </div>
                </div>
                <div className="text-left">
                  <h5 className="text-justify  text-sm md:text-base ml-0 text-[#1C1F37]">
                    As an event manager, i have been able to benefit
                    tremendously from the services rendered here. The workers
                    dispatched to me are hard-working and friendly. The prices
                    are also pocket friendly
                  </h5>
                </div>
              </div>
            </div>
            <div class="carousel-item float-left w-full">
              {/* single-card 2 */}
              <div className=" bg-white border-2 border-[#C44B99] shadow rounded-lg px-4 py-4  max-w-xs mx-auto md:max-w-md xl:max-w-lg ">
                {/* avatar */}
                <div className="flex justify-start items-center gap-4">
                  <div className="w-16 md:w-24">
                    <img src={test2} alt="" />
                  </div>
                  <div className="space-y-1">
                    <h3 className="text-lg mt-0 mb-0 md:text-left">
                      Natasha Freda
                    </h3>
                    <h5 className="mt-0 text-left text-[#C44B99]">
                      Houston TX
                    </h5>
                    <div className="flex">
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className=" text-bold" />
                    </div>
                  </div>
                </div>
                <div className="text-left">
                  <h5 className="text-justify  text-sm md:text-base ml-0 text-[#1C1F37] ">
                    I was introduced to this agency by my colleague and ever
                    since i employed their services i have seen considerable
                    changes in the health of my aged mom. I woud definetly
                    encourage everyone to try them out
                  </h5>
                </div>
              </div>
            </div>
            <div class="carousel-item float-left w-full">
              {/* single-card 3 */}
              <div className=" bg-white shadow rounded-lg px-4 py-4  border-2 border-[#C44B99] max-w-xs mx-auto md:max-w-md xl:max-w-lg">
                {/* avatar */}
                <div className="flex justify-start items-center gap-4">
                  <div className="w-16 md:w-24">
                    <img src={test3} alt="" />
                  </div>
                  <div className="space-y-1">
                    <h3 className="text-lg mt-0 mb-0 md:text-left">
                      Jessica Howarld
                    </h3>
                    <h5 className="mt-0 text-left text-[#C44B99]">
                      Tennesse USA
                    </h5>
                    <div className="flex">
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                    </div>
                  </div>
                </div>
                <div className="text-left">
                  <h5 className="text-justify  text-sm md:text-base ml-0 text-[#1C1F37] ">
                    All my inter-state deliveries are done using the glorious
                    transportation service and my goods get delivered in record
                    time and in perfect conditions
                  </h5>
                </div>
              </div>
            </div>
            <div class="carousel-item float-left w-full">
              {/* single-card 4 */}
              <div className=" bg-white shadow rounded-lg px-4 py-4 border-2 border-[#C44B99] max-w-xs mx-auto md:max-w-md xl:max-w-lg">
                {/* avatar */}
                <div className="flex justify-start items-center gap-4">
                  <div className="w-16 md:w-24">
                    <img src={test4} alt="" />
                  </div>
                  <div className="space-y-1">
                    <h3 className="text-lg mt-0 mb-0 md:text-left">
                      John Henderson
                    </h3>
                    <h5 className="mt-0 text-left text-[#C44B99]">Texas</h5>
                    <div className="flex">
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                      <AiFillStar className="text-yellow-500 text-bold" />
                    </div>
                  </div>
                </div>
                <div className="text-left">
                  <h5 className="text-justify  text-sm md:text-base ml-0 text-[#1C1F37] ">
                    I manage a series of hotels and over the years i have been
                    able to bank of the services of the glorious hospitality
                    services for the smooth running of these facilities. Their
                    services are top notch and i totally recommend them for any
                    business.
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              class="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              class="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      {/* stay in touch */}
      <section className="section-center px-4">
        <div className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-alata rounded-lg px-4 py-6 mx-auto space-y-8 lg:py-12 lg:space-y-12 ">
          <h3 className=" font-semibold mt-0 text-lg w-64 md:text-2xl lg:text-3xl lg:w-full text-center mx-auto">
            Stay ahead of the crowd, get in-touch{" "}
            <br className="hidden lg:block" /> with us today !
          </h3>
          {/* call to action */}
          <div className="flex flex-col md:flex-row justify-center items-center gap-2 space-y-3 md:space-y-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/12027057631"
              className="bg-white text-[#C44B99] rounded-md py-1 md:py-2 px-4 hover:text-white hover:bg-[#1C1F37]"
            >
              Let's Talk
            </a>
            <Link
              to={"/generalForm"}
              className="border border-white text-white rounded-md py-1 md:py-2 px-4 hover:bg-[#1C1F37] "
            >
              Book an Appointment
            </Link>
          </div>
        </div>
      </section>
      {/* footer */}
      <Footer />
    </>
  );
};
export default Home;
