import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../Assets/ghs-logo.png";

const Header = () => {
  const [pannel, setPannel] = useState("1");
  const { pathname } = useLocation();
  return (
    <nav className="sticky top-0 z-50  md:static">
      {/* <!-- Menu/Logo Container --> */}
      <div class="flex items-center justify-between text-[#1C1F37] pl-2 pr-6 md:px-4 py-6 bg-white shadow-sm shadow-[#C44B99] lg:px-12">
        {/* <!-- Logo --> */}
        <Link to="/">
          <img src={logo} alt="" />{" "}
        </Link>

        {/* <!-- Menu --> */}
        <div class="hidden h-10 font-alata font-semibold  md:flex md:space-x-12 md:justify-center md:items-center ">
          <div class="group">
            <Link to="/healthcare">Healthcare</Link>
            <div
              class={`mx-2 group-hover:border-b-2 group-hover:border-[#C44B99] ${
                pathname === "/healthcare" && "border-[#C44B99] border-b-2"
              }`}
            ></div>
          </div>
          <div class="group">
            <Link to="/hospitality">Hospitality</Link>
            <div
              class={`mx-2 group-hover:border-b-2 group-hover:border-[#C44B99] ${
                pathname === "/hospitality" && "border-[#C44B99] border-b-2"
              }`}
            ></div>
          </div>
          <div class="group">
            <Link to="/transportation">Transportation</Link>
            <div
              class={`mx-2 group-hover:border-b-2 group-hover:border-[#C44B99] ${
                pathname === "/transportation" && "border-[#C44B99] border-b-2"
              }`}
            ></div>
          </div>
          <div class="group">
            <Link to="/career">Career</Link>
            <div
              class={`mx-2 group-hover:border-b-2 group-hover:border-[#C44B99] ${
                pathname === "/career" && "border-[#C44B99] border-b-2"
              }`}
            ></div>
          </div>
        </div>
        {/* call to action */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://wa.me/12027057631"
          className="hidden md:block px-6 py-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-alata font-semibold animate-pulse shadow-md"
        >
          Live Chat
        </a>
        {/* <!-- Hamburger Button --> */}
        <div class="md:hidden animate-pulse  " onClick={() => setPannel("2")}>
          <button
            id="menu-btn"
            type="button"
            class="z-40 block hamburger md:hidden focus:outline-none "
          >
            <span class="hamburger-top"></span>
            <span class="hamburger-middle"></span>
            <span class="hamburger-bottom"></span>
          </button>
        </div>
      </div>

      {/* <!-- revealed Mobile Menu --> */}
      {pannel === "2" ? (
        <div
          id="menu"
          class="fixed top-0 bottom-0 left-0  flex flex-col items-center self-end w-full h-full py-1 pt-40 space-y-6 text-lg text-white font-alata font-medium bg-black z-50  md:hidden"
        >
          <div className="absolute top-10 right-12 animate-pulse">
            <button
              id="menu-btn"
              type="button"
              class="z-40 block open hamburger md:hidden focus:outline-none duration-1000 "
              onClick={() => setPannel("3")}
            >
              <span class="open hamburger-top"></span>
              <span class="open hamburger-middle"></span>
              <span class="open hamburger-bottom"></span>
            </button>
          </div>
          <Link to="/" class="hover:text-[#C44B99]">
            Home
          </Link>
          <Link to="/healthcare" class="hover:text-[#C44B99]">
            Healthcare
          </Link>
          <Link to="/hospitality" class="hover:text-[#C44B99]">
            Hospitality
          </Link>
          <Link to="/transportation" class="hover:text-[#C44B99]">
            Transportation
          </Link>
          <Link to="/career" class="hover:text-[#C44B99]">
            Career
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/12027057631"
            className=" px-8 py-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-alata font-semibold animate-pulse shadow-md"
          >
            Live Chat
          </a>
        </div>
      ) : (
        <div
          id="menu"
          class=" hidden absolute top-0 bottom-0 left-0  flex-col items-center self-end w-full min-h-screen py-1 pt-40 space-y-6 text-lg text-white font-alata font-medium bg-black  md:hidden"
        >
          <Link to="/" class="hover:text-[#C44B99] ">
            Home
          </Link>
          <Link to="/healthcare" class="hover:text-[#C44B99] ">
            Healthcare
          </Link>
          <Link to="/hospitality" class="hover:text-[#C44B99]">
            Hospitality
          </Link>
          <Link to="/transportation" class="hover:text-[#C44B99]">
            Transportation
          </Link>
          <Link to="/career" class="hover:text-[#C44B99]">
            Career
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/12027057631"
            className=" px-8 py-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-alata font-semibold animate-pulse shadow-md duration-1000"
          >
            Live Chat
          </a>
        </div>
      )}
    </nav>
  );
};
export default Header;
