import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import useCountries from "../hooks/useCountriesHook";
import useUserCountry from "../hooks/useUserCountry";
import { validateEmail } from "../utils/validation";
import phone from "phone";
import { CareerFormAPI } from "../api/api";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import CheckInputForm from "./CheckInputForm";
const CareerForm = () => {
  const [loading, setLoading] = useState(false);
  const { countries } = useCountries();
  const { userCountry } = useUserCountry();
  const [formData, setFormData] = useState({
    fullname: "",
    address: "",
    country: userCountry,
    state: "",
    email: "",
    zip: "",
    phone: "",
    employment: {
      position: "",
      days_available: "",
      shift_available: false,
      work_overtime: false,
      desired_salary: "",
      transportation: false,
      platform: "",
    },
    personal: {
      isApplied: false,
      isFriendsWorking: false,
      isOlder: true,
      isUsCitizen: false,
      isAccomodation: false,
      isCriminal: false,
      substance_test: false,
    },
    skills: "",
    education: {
      high_school: {
        name: "",
        locaton: "",
        year_graduated: "",
        degree: "",
      },
      college: {
        name: "",
        locaton: "",
        year_graduated: "",
        degree: "",
      },
      vocational_school: {
        name: "",
        locaton: "",
        year_graduated: "",
        degree: "",
      },
    },
    military: {
      isArmedForces: false,
      branch: "",
      rank: "",
      years_of_service: "",
    },
    previous_employment: {
      employer_name: "",
      employer_address: "",
      job_title: "",
      employer_phone: "",
      city: "",
      state: "",
      zip: "",
      date: "",
      reason_for_leaving: "",
      supervisor_name: "",
    },
    references: {
      reference: "",
      contact: "",
    },
    strength: "",
    weakness: "",
    method_of_arrival: "",
    signature: "",
  });
  const [errors, setErrors] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    const { email, phone: phone_number, address, fullname } = formData;
    if (fullname?.trim() === "") {
      return setErrors((prev) => [...prev, "Full Name is required"]);
    }
    if (email?.trim() === "") {
      return setErrors((prev) => [...prev, "Email is required"]);
    }
    if (!validateEmail(email)) {
      return setErrors((prev) => [...prev, "Kindly provide a valid email"]);
    }
    if (phone_number?.trim() === "") {
      return setErrors((prev) => [...prev, "Phone is required"]);
    }
    const result = phone(phone_number, { country: formData?.country });
    if (result?.isValid === false) {
      return setErrors((prev) => [
        ...prev,
        "Kindly provide a valid phone number",
      ]);
    }
    if (address?.trim() === "") {
      return setErrors((prev) => [...prev, "Address is required"]);
    }
    setLoading(true);
    const results = await CareerFormAPI(formData);
    console.log(results);
    if (results?.success === true) {
      toast.success("Form Submitted Successfuly!", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrors([]);
      return setLoading(false);
    }
    toast.error(
      results?.error?.join("") ?? "Something went Wrong, Kindly Retry",
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
    return setLoading(false);
  };

  useEffect(() => {
    setFormData((prev) => ({ ...prev, country: userCountry }));
  }, [userCountry]);
  return (
    <>
      <Header />
      {/* Title */}
      <section className="section-center px-4">
        <div className="bg-gradient-to-r from-purple-500 to-pink-500 px-4 py-5 shadow-sm rounded-md">
          <h3 className="text-white mt-0 text-3xl lg:text-4xl lg:py-6">
            Application Form
          </h3>
        </div>
      </section>
      {/* form */}
      <section className="section-center px-4">
        <form onSubmit={handleSubmit} className="space-y-16">
          {/* first input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">Application Form</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* input */}
            <div className="space-y-4 lg:grid lg:grid-rows-2 lg:grid-cols-5 lg:space-y-0 lg:gap-x-4 lg:gap-y-8 xl:gap-y-10 items-end">
              {/* Applicant Name */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Applicant Name" className="font-alata text-lg">
                  Applicant Name
                </label>
                <input
                  value={formData?.fullname}
                  type="Name"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      fullname: e.target.value,
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Address */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Address" className="font-alata text-lg">
                  Address
                </label>
                <input
                  type="text"
                  value={formData?.address}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      address: e.target.value,
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* city */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Address" className="font-alata text-lg">
                  Country
                </label>
                <select
                  type="text"
                  defaultValue={userCountry}
                  value={formData?.country}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      country: e.target.value,
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                >
                  {countries?.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              {/* State */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Address" className="font-alata text-lg">
                  State
                </label>
                <input
                  type="text"
                  value={formData?.state}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, state: e.target.value }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* zip */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Address" className="font-alata text-lg">
                  Zip
                </label>
                <input
                  type="text"
                  value={formData?.zip}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, zip: e.target.value }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* telephone */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Address" className="font-alata text-lg">
                  Telephone Number
                </label>
                <input
                  type="number"
                  value={formData?.phone}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, phone: e.target.value }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Email */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Address" className="font-alata text-lg">
                  Email
                </label>
                <input
                  type="email"
                  value={formData?.email}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, email: e.target.value }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
            </div>
          </div>
          {/* second input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">Employment Position</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* input */}
            <div className="space-y-4 lg:grid lg:grid-rows-3 lg:grid-cols-3 lg:space-y-0 lg:gap-x-4 lg:gap-y-8 xl:gap-y-10 items-end">
              {/* Position applying for */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Position applying for"
                  className="font-alata text-lg"
                >
                  Position applying for
                </label>
                <input
                  type="text"
                  value={formData?.employment?.position}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      employment: {
                        ...prev.employment,
                        position: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* How did you hear about this position */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="How did you hear about this position"
                  className="font-alata text-lg"
                >
                  How did you hear about this position
                </label>
                <select
                  type="text"
                  value={formData?.employment?.platform}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      employment: {
                        ...prev.employment,
                        platform: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                >
                  <option value={"facebook"}>Facebook</option>
                  <option value={"linkedin"}>LinkedIn</option>
                  <option value={"indeed"}>Indeed</option>
                  <option value={"Smartrecruiters"}>Smart Recruiters</option>
                  <option value={"glassdoor"}>Glassdoor</option>
                  <option value={"twitter"}>Twitter</option>
                  <option value={"Oothers"}>Others</option>
                </select>
              </div>
              {/* What days are you available for work */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="What days are you available for work"
                  className="font-alata text-lg"
                >
                  What days are you available for work
                </label>
                <input
                  type="text"
                  value={formData?.employment?.days_available}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      employment: {
                        ...prev.employment,
                        days_available: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Shift available */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Shift available" className="font-alata text-lg">
                  Shift available
                </label>
                <select
                  type="text"
                  value={formData?.employment?.shift_available}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      employment: {
                        ...prev.employment,
                        shift_available: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                >
                  <option value={"Day"}>Day</option>
                  <option value={"Night"}>Night</option>
                </select>
              </div>
              {/* Are you available to work overtime */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Are you available to work overtime"
                  className="font-alata text-lg"
                >
                  Are you available to work overtime
                </label>
                <select
                  type="text"
                  value={formData?.employment?.work_overtime}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      employment: {
                        ...prev.employment,
                        work_overtime: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              {/* Do you have reliable transportation */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Do you have reliable transportation"
                  className="font-alata text-lg"
                >
                  Do you have reliable transportation
                </label>
                <select
                  type="text"
                  value={formData?.employment?.transportation}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      employment: {
                        ...prev.employment,
                        transportation: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              {/* Desired Salary expected */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Address" className="font-alata text-lg">
                  Desired Salary expected (in $)
                </label>
                <input
                  type="amount"
                  value={formData?.employment?.desired_salary}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      employment: {
                        ...prev.employment,
                        desired_salary: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
            </div>
          </div>
          {/* third input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">Personal Information</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* input */}
            <div className="space-y-4 lg:grid lg:grid-rows-4 lg:grid-cols-2 lg:space-y-0 lg:gap-x-8 lg:gap-y-8 xl:gap-y-10 xl:gap-x-16 items-start">
              {/* Have you ever applied to or worked for Glorious Healthcare Services before? */}
              <CheckInputForm
                title={
                  "Have you ever applied to or worked for Glorious Healthcare Services before?"
                }
                field="isApplied"
                setField={setFormData}
                mainField={formData}
              />

              {/* Do you have any friends, relatives, or acquaintances working for Glorious Healthcare Services
               */}
              <CheckInputForm
                title={
                  "Do you have any friends, relatives, or acquaintances working for Glorious Healthcare Services"
                }
                field="isFriendsWorking"
                setField={setFormData}
                mainField={formData}
              />

              {/* Are you 18 years of age or older?
               */}
              {/* <div className="space-y-2">
                <h5 className="mx-0 text-left max-w-lg text-black">
                  Are you 18 years of age or older?
                </h5>
                <div className="flex flex-row justify-start items-center gap-4  ">
                  <input type="Checkbox" className="" />
                  <label
                    htmlFor=" Are you 18 years of age or older?"
                    className="font-alata text-lg"
                  >
                    yes
                  </label>
                </div>
                <div className="flex flex-row justify-start items-center gap-4  ">
                  <input type="Checkbox" className="" />
                  <label
                    htmlFor=" Are you 18 years of age or older?"
                    className="font-alata text-lg"
                  >
                    No
                  </label>
                </div>
              </div> */}
              <CheckInputForm
                title={"Are you 18 years of age or older?"}
                field="isOlder"
                setField={setFormData}
                mainField={formData}
              />
              {/* Are you a U.S. citizen or approved to work in the United States? */}
              <CheckInputForm
                title={
                  "Are you a U.S. citizen or approved to work in the United States?"
                }
                field="isUsCitizen"
                setField={setFormData}
                mainField={formData}
              />

              {/* What document can you provide as proof of citizenship or legal status?
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="What document can you provide as proof of citizenship or legal status?
"
                  className="font-alata text-lg"
                >
                  What document can you provide as proof of citizenship or legal
                  status?
                </label>
                <input
                  type="text"
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Will you consent to a mandatory controlled substance test?
               */}
              <CheckInputForm
                title={
                  "Will you consent to a mandatory controlled substance test?"
                }
                field="substance_test"
                setField={setFormData}
                mainField={formData}
              />

              {/* Do you have any condition which would require job accommodations?
               */}
              <CheckInputForm
                title={
                  "Do you have any condition which would require job accommodations?"
                }
                field="isAccomodation"
                setField={setFormData}
                mainField={formData}
              />

              {/* Have you ever been convicted of a criminal offense (felony or misdemeanor)?

               */}
              <CheckInputForm
                title={
                  "Have you ever been convicted of a criminal offense (felony or misdemeanor)?"
                }
                field="isCriminal"
                setField={setFormData}
                mainField={formData}
              />
            </div>
            {/* note */}
            <h5 className="text-[#C44B99] max-w-6xl text-justify">
              (Note: No applicant will be denied employment solely on the
              grounds of conviction of a criminal offense. The date of the
              offense, the nature of the offense, including any significant
              details that affect the description of the event, and the
              surrounding circumstances and the relevance of the offense to the
              position(s) applied for may, however, be considered.)
            </h5>
          </div>
          {/* fourth input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">
                Job Skills / Qualifications
              </h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>

            <h5 className="text-[#C44B99] max-w-6xl text-justify">
              Please list below the skills and qualifications you possess for
              the position for which you are applying:
            </h5>
            {/* input */}
            <div className="space-y-4">
              {/* What document can you provide as proof of citizenship or legal status?
               */}

              <div className="flex flex-col space-y-2">
                <label htmlFor="Description" className="font-alata text-lg">
                  Description
                </label>
                <textarea
                  type="text"
                  value={formData?.skills}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, skills: e.target.value }))
                  }
                  className="font-alata resize-none border px-2 py-2 text-sm max-w-6xl outline-[#C44B99] rounded-md h-32"
                ></textarea>
              </div>
            </div>
            {/* note */}
            <h5 className="text-[#C44B99] max-w-6xl text-justify">
              (Note: Glorious Healthcare Services complies with the ADA and
              considers reasonable accommodation measures that may be necessary
              for eligible applicants/employees to perform essential functions.
              It is possible that a hire may be tested on skill/agility and may
              be subject to a medical examination conducted by a medical
              professional. )
            </h5>
          </div>
          {/* fifth input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">Education and Training</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* input */}
            <div className="space-y-8 ">
              {/* first group */}
              <div className="space-y-4">
                <h5 className="text-[#C44B99] max-w-6xl text-justify">
                  High school
                </h5>
                <div className="space-y-4 lg:space-y-0  lg:grid lg:grid-cols-2 lg:gap-x-6 lg:gap-y-6 xl:grid-cols-4">
                  {/* Name */}
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="Name" className="font-alata text-lg">
                      Name
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.high_school?.name}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            high_school: {
                              ...prev.education.high_school,
                              name: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Location (City, State)
                   */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Location (City, State)
"
                      className="font-alata text-lg"
                    >
                      Location (City, State)
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.high_school?.locaton}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            high_school: {
                              ...prev.education.high_school,
                              locaton: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Year Graduated*/}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Year Graduated"
                      className="font-alata text-lg"
                    >
                      Year Graduated
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.high_school?.year_graduated}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            high_school: {
                              ...prev.education.high_school,
                              year_graduated: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Degree Earned */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Degree Earned"
                      className="font-alata text-lg"
                    >
                      Degree Earned
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.high_school?.degree}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            high_school: {
                              ...prev.education.high_school,
                              degree: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                </div>
              </div>
              {/* second group */}
              <div className="space-y-4">
                <h5 className="text-[#C44B99] max-w-6xl text-justify">
                  College/University
                </h5>
                <div className="space-y-4 lg:space-y-0  lg:grid lg:grid-cols-2 lg:gap-x-6 lg:gap-y-6 xl:grid-cols-4">
                  {/* Name */}
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="Name" className="font-alata text-lg">
                      Name
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.college?.name}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            college: {
                              ...prev.education.college,
                              name: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Location (City, State)
                   */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Location (City, State)
"
                      className="font-alata text-lg"
                    >
                      Location (City, State)
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.college?.locaton}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            college: {
                              ...prev.education.college,
                              locaton: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Year Graduated*/}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Year Graduated"
                      className="font-alata text-lg"
                    >
                      Year Graduated
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.college?.year_graduated}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            college: {
                              ...prev.education.college,
                              year_graduated: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Degree Earned */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Degree Earned"
                      className="font-alata text-lg"
                    >
                      Degree Earned
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.college?.degree}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            college: {
                              ...prev.education.college,
                              degree: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                </div>
              </div>
              {/* third group */}
              <div className="space-y-4">
                <h5 className="text-[#C44B99] max-w-6xl text-justify">
                  Vocational School/Specialized Training
                </h5>
                <div className="space-y-4 lg:space-y-0  lg:grid lg:grid-cols-2 lg:gap-x-6 lg:gap-y-6 xl:grid-cols-4">
                  {/* Name */}
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="Name" className="font-alata text-lg">
                      Name
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.vocational_school?.name}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            vocational_school: {
                              ...prev.education.vocational_school,
                              name: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Location (City, State)
                   */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Location (City, State)
"
                      className="font-alata text-lg"
                    >
                      Location (City, State)
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.vocational_school?.locaton}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            vocational_school: {
                              ...prev.education.vocational_school,
                              locaton: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Year Graduated*/}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Year Graduated"
                      className="font-alata text-lg"
                    >
                      Year Graduated
                    </label>
                    <input
                      type="text"
                      value={
                        formData?.education?.vocational_school?.year_graduated
                      }
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            vocational_school: {
                              ...prev.education.vocational_school,
                              year_graduated: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                  {/* Degree Earned */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="Degree Earned"
                      className="font-alata text-lg"
                    >
                      Degree Earned
                    </label>
                    <input
                      type="text"
                      value={formData?.education?.vocational_school?.degree}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education: {
                            ...prev.education,
                            vocational_school: {
                              ...prev.education.vocational_school,
                              degree: e.target.value,
                            },
                          },
                        }))
                      }
                      className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* sixth input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">Military</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* input */}
            <div className="space-y-4 lg:grid lg:grid-rows-2 lg:grid-cols-2 lg:space-y-0 lg:gap-x-4 lg:gap-y-8 xl:gap-y-10 items-end">
              {/* Are you a member of the Armed Services?
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Are you a member of the Armed Services?
"
                  className="font-alata text-lg"
                >
                  Are you a member of the Armed Services?
                </label>
                <select
                  type="text"
                  value={formData?.military?.isArmedForces}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      military: {
                        ...prev.military,
                        isArmedForces: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
              {/* What branch of the military did you enlist?
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="What branch of the military did you enlist?
"
                  className="font-alata text-lg"
                >
                  What branch of the military did you enlist?
                </label>
                <input
                  type="text"
                  value={formData?.military?.branch}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      military: { ...prev.military, branch: e.target.value },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* What was your military rank when discharged?
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="What was your military rank when discharged?"
                  className="font-alata text-lg"
                >
                  What was your military rank when discharged?
                </label>
                <input
                  type="text"
                  value={formData?.military?.rank}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      military: { ...prev.military, rank: e.target.value },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* How many years did you serve in the military?
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="How many years did you serve in the military?
"
                  className="font-alata text-lg"
                >
                  How many years did you serve in the military?
                </label>
                <input
                  type="Number"
                  value={formData?.military?.years_of_service}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      military: {
                        ...prev.military,
                        years_of_service: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
            </div>
          </div>
          {/* seventh input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">Previous Employment</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* input */}
            <div className="space-y-4 lg:grid md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 lg:space-y-0 lg:gap-x-4 lg:gap-y-8 xl:gap-y-10 items-end">
              {/* Employer Name

               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Employer Name

"
                  className="font-alata text-lg"
                >
                  Employer Name
                </label>
                <input
                  type="text"
                  value={formData?.previous_employment?.employer_name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        employer_name: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Job Title
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Job Title
"
                  className="font-alata text-lg"
                >
                  Job Title
                </label>
                <input
                  type="text"
                  value={formData?.previous_employment?.job_title}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        job_title: e.target.value,
                      },
                    }))
                  }
                  // value={formData?.previous_employment?}
                  // onChange={(e)=> setFormData(prev => ({...prev, previous_employment: {...prev.previous_employment, employer_name: e.target.value}}))}
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Supervisor Name
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Supervisor Name
"
                  className="font-alata text-lg"
                >
                  Supervisor Name
                </label>
                <input
                  type="text"
                  value={formData?.previous_employment?.supervisor_name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        supervisor_name: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Employer Address

               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Employer Address

"
                  className="font-alata text-lg"
                >
                  Employer Address
                </label>
                <input
                  type="Address"
                  value={formData?.previous_employment?.employer_address}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        employer_address: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* City

               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="City

"
                  className="font-alata text-lg"
                >
                  City
                </label>
                <input
                  type="text"
                  value={formData?.previous_employment?.city}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        city: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* State


               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="State


"
                  className="font-alata text-lg"
                >
                  State
                </label>
                <input
                  type="text"
                  value={formData?.previous_employment?.state}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        state: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Zip

               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Zip

"
                  className="font-alata text-lg"
                >
                  Zip
                </label>
                <input
                  type="text"
                  value={formData?.previous_employment?.zip}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        zip: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Employer Telephone


               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Employer Telephone


"
                  className="font-alata text-lg"
                >
                  Employer Telephone
                </label>
                <input
                  type="number"
                  value={formData?.previous_employment?.employer_phone}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        employer_phone: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Dates Employed


               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Dates Employed
"
                  className="font-alata text-lg"
                >
                  Dates Employed
                </label>
                <input
                  type="date"
                  value={formData?.previous_employment?.date}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        date: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Reason for leaving


               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Reason for leaving


"
                  className="font-alata text-lg"
                >
                  Reason for leaving
                </label>
                <input
                  type="text"
                  value={formData?.previous_employment?.reason_for_leaving}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      previous_employment: {
                        ...prev.previous_employment,
                        reason_for_leaving: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
            </div>
          </div>
          {/* eight input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">References</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* input */}
            <div className="space-y-4 md:grid md:grid-cols-2 md:space-y-0 md:gap-x-12 items-end">
              {/* Reference */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Reference" className="font-alata text-lg">
                  Reference
                </label>
                <input
                  type="text"
                  value={formData?.references?.reference}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      references: {
                        ...prev.references,
                        reference: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Contact Information */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Contact Information"
                  className="font-alata text-lg"
                >
                  Contact Information
                </label>
                <input
                  type="number"
                  value={formData?.references?.contact}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      references: {
                        ...prev.references,
                        contact: e.target.value,
                      },
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
            </div>
          </div>
          {/* nineth input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">Additional Information</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* input */}
            <div className="space-y-4 ">
              {/* 
Can you list two or three of your greatest strengths?
 */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="
Can you list two or three of your greatest strengths?
"
                  className="font-alata text-lg"
                >
                  Can you list two or three of your greatest strengths?
                </label>
                <textarea
                  type="text"
                  value={formData?.strength}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      strength: e.target.value,
                    }))
                  }
                  className="font-alata resize-none border px-2 py-2 text-sm max-w-6xl outline-[#C44B99] rounded-md h-32"
                ></textarea>
              </div>
              {/* Can you list two or three of your greatest weaknesses? */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Can you list two or three of your greatest weaknesses?"
                  className="font-alata text-lg"
                >
                  Can you list two or three of your greatest weaknesses?
                </label>
                <textarea
                  type="text"
                  value={formData?.weakness}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      weakness: e.target.value,
                    }))
                  }
                  className="font-alata resize-none border px-2 py-2 text-sm max-w-6xl outline-[#C44B99] rounded-md h-32"
                ></textarea>
              </div>
              {/* How did you hear about us?
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="How did you hear about us?
"
                  className="font-alata text-lg"
                >
                  How did you hear about us?
                </label>
                <select
                  type="text"
                  value={formData?.method_of_arrival}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      method_of_arrival: e.target.value,
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-lg outline-[#C44B99] rounded-md"
                >
                  <option value="indeed">Indeed</option>
                  <option value="linkedin">LinkedIn</option>
                  <option value="facebook">Facebook</option>
                  <option value="twitter">Twitter</option>
                </select>
              </div>
            </div>
          </div>
          {/* tenth input */}
          <div className="space-y-8">
            {/* title */}
            <div>
              <h3 className="mt-0 text-xl text-left">AT-WILL EMPLOYMENT</h3>
              <div className="border-b-4 mt-2 w-24 border-[#C44B99]"></div>
            </div>
            {/* note */}
            <h5 className="text-[#C44B99] max-w-6xl text-justify">
              The relationship between you and the Glorious Healthcare Services
              is referred to as "employment at will." This means that your
              employment can be terminated at any time for any reason, with or
              without cause, with or without notice, by you or the Glorious
              Healthcare Services. No representative of Glorious Healthcare
              Services has authority to enter into any agreement contrary to the
              foregoing "employment at will" relationship. You understand that
              your employment is "at will," and that you acknowledge that no
              oral or written statements or representations regarding your
              employment can alter your at-will employment status, except for a
              written statement signed by you and either our Executive
              VicePresident/Chief Operations Officer or the Company's President.
            </h5>
            {/* input */}
            <div className="space-y-4 md:grid md:grid-cols-2 md:space-y-0 md:gap-x-12 items-end">
              {/* Applicant Signature
               */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="Applicant Signature
"
                  className="font-alata text-lg"
                >
                  Applicant Signature
                </label>
                <input
                  type="text"
                  value={formData?.signature}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      signature: e.target.value,
                    }))
                  }
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
              {/* Dated */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="Dated" className="font-alata text-lg">
                  Dated
                </label>
                <input
                  type="date"
                  className="font-alata border px-2 py-2 text-sm max-w-2xl outline-[#C44B99] rounded-md"
                />
              </div>
            </div>
          </div>
          {/* submit */}
          {errors?.length > 0 &&
            errors?.map((error, idx) => (
              <p
                key={idx}
                className="text-white px-5 py-2 w-max bg-red-400 font-semibold"
              >
                {error}
              </p>
            ))}
          <button
            type="submit"
            className="mx-auto w-32 text-center px-6 rounded-md hover:-translate-y-2 active:translate-y-0 shadow-md text-white py-2.5 bg-[#C44B99] font-sans flex justify-center"
          >
            {loading ? <FaSpinner className="animate-spin" /> : "Submit"}
          </button>
        </form>
      </section>
      {/* footer */}
      <Footer />
    </>
  );
};
export default CareerForm;
