import React from 'react'
import FormHero from '../Components/FormHero'
import TransportationComp from '../Components/TransportationComp'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

const TransportationForm = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <FormHero />
      <section className='section-center mx-auto px-4 xl:mx-auto '>
        <TransportationComp />
      </section>
      <Footer />
    </>
  )
}
export default TransportationForm
