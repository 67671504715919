import React from 'react'
import FormHero from '../Components/FormHero'
import HospitalityComp from '../Components/HospitalityComp'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

const HospitalityForm = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <FormHero />
      <section className='section-center mx-auto px-4 xl:mx-auto '>
        <HospitalityComp />
      </section>
      <Footer />
    </>
  )
}
export default HospitalityForm
