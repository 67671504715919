import React from 'react'
import FormHero from '../Components/FormHero'
import HealthcareComp from '../Components/HealthcareComp'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

const HealthcareForm = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <FormHero />
      <section className='section-center mx-auto px-4 xl:mx-auto '>
        <HealthcareComp />
      </section>
      <Footer />
    </>
  )
}
export default HealthcareForm
